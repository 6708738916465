import { Box, Chip, Grid, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid, gridClasses, GridFooterContainer } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddTransferAsset from './AddTransferAsset';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomPagination from '../Utils/CustomPagination';
import useSortModel from '../../hooks/UseSortModel';


const CustomFooter = ({ totalHaulingRate, selectedRows, totalOtherCharges }) => {
  const formattedTotal = new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(totalHaulingRate);
  const formattedTotalCharges = new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(totalOtherCharges);

  return (
    <GridFooterContainer>
      {/* Your custom footer content */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: '#f5f5f5' }}>
        {selectedRows > 0 ? (
          <Typography variant="subtitle1">
            {selectedRows} {selectedRows === 1 ? 'row' : 'rows'} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1">
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Total Hauling Amount: {formattedTotal}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Other Charges total: {formattedTotalCharges}
        </Typography>
      </Box>
      
      {/* Default pagination footer */}
      <CustomPagination /> 
    </GridFooterContainer>
  );
}


const TransferAssetsWarehouse = () => {
  const axios = AxiosCustomConfig();
  const [onEdit, setOnEdit] = useState(false);
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'warehouse_transfer';
  const modelTable = 'TransferAssetRequests';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [totalHaulingRate, setTotalHaulingRate] = useState(0);
  const [totalOtherCharges, setTotalOtherCharges] = useState(0);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    { field: 'id', headerName: 'Id', minWidth: 50, wrap: true,
    },
    { field: 'request_code', headerName: 'Transfer request #',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/transferAssets/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
        field: 'status', 
        headerName: 'Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
            const status = params.value;
            if (status === 0) {
              return <Chip label='For approval' sx={{ backgroundColor: '#35A7CA' }} />;
            } else if (status === 1) {
              return <Chip label='Delivery in progress' sx={{ backgroundColor: '#D7D6D4'}} />;
            } else if (status === 2) {
              return <Chip label='Completed' sx={{ backgroundColor: '#3AD658' }} />;
            } else if (status === 3) {
              return <Chip label='Delivered' color='success' />;
            } else if (status === 4) {
              return <Chip label='Transfer form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
            } else if (status === 5) {
              return <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
            } else if (status === 6) {
              return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
            } else if (status === 7) {
              return <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />;
            } else {
              return '';
            }
            
        }
    },
    { field: 'assets', headerName: 'Assets',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'origin_warehouse_name', headerName: 'From warehouse',flex: 1, minWidth: 150,},
    { field: 'destination_warehouse_name', headerName: 'Destination warehouse',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Date Uploaded',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'requestor_name', headerName: 'Requested by',flex: 1, minWidth: 150,},
    { field: 'hauling_rate_name', headerName: 'Hauling rate name',flex: 1, minWidth: 150,},
    { field: 'hauling_rate', headerName: 'Hauling Ammount',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const rate = params ? params.toFixed(2) : ''
        return rate;
      }
    },
    { field: 'other_charges', headerName: 'Other chagres total',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const rate = params ? params.toFixed(2) : 0
        return rate;
      }
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/TransferAssetRequests/getAllTransferRequests.json',
        }
        const response = await axios(options);
        const data = response.data;

        setAssetDisposalData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEdit]);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/TransferAssetRequests/getAllTransferRequestsId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  useEffect(() => {
    const getTotalHaulingRate = async () => {
      try {
        const options = {
          'method': 'POST',
          'url' : '/TransferAssetRequests/getTotalHaulingRate.json',
        }
        const response = await axios(options);
        const { totalHaulingRate } = response.data;

        setTotalHaulingRate(totalHaulingRate)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getTotalHaulingRate();
  }, []);
  
  useEffect(() => {
    const getTotalOtherCharges = async () => {
      try {
        const options = {
          'method': 'POST',
          'url' : '/TransferAssetRequests/getTotalOtherCharges.json',
        }
        const response = await axios(options);
        const { totalOtherCharges } = response.data;
        
        setTotalOtherCharges(totalOtherCharges)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getTotalOtherCharges();
  }, []);

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AddTransferAsset setOnEdit={setOnEdit}/>
      </Box>
      
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={assetDisposalData}
        columns={filteredAndOrderedColumns}
        getRowHeight={() => 'auto'}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['asc', 'desc']}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar
            selectedRows={rowSelectionModel} 
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            modelTable={modelTable}
            handleClickAllData={handleClickAllData}
            selectAll={selectAll}
          />,
          footer: () => <CustomFooter totalHaulingRate={totalHaulingRate} selectedRows={rowSelectionModel.length} totalOtherCharges={totalOtherCharges}/>
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default TransferAssetsWarehouse