import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, StyledEngineProvider, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UpdateChannel({accountsData, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    account_code: '',
    channel: '',
    channel_array: [],
    distributor_company_id: '',
    unique_code: '',
    id : ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    
    const { customer_no, id, channel, distributor_company_id, unique_code} = accountsData;

    setEditData((prevData) => ({ 
      ...prevData, 
      account_code: customer_no || prevData.customer_no, 
      id: id || prevData.id,
      channel_array: {'label' : channel, 'value': channel},
      channel: channel,
      old_channel: channel,
      distributor_company_id: distributor_company_id,
      unique_code: unique_code,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      account_code: '',
      channel: '',
      channel_array: [],
      distributor_company_id: '',
      unique_code: '',
      id : ''
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = ['channel'];

    for (const field of requiredFields) {
      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(editData.account_name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.description) && editData.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiAccounts/updateChannel.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)

        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      console.log(error);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const channel = [
    {'label': 'Bakery', 'value': 'Bakery'},
    {'label': 'CVS/Petrol', 'value': 'CVS/Petrol'},
    {'label': 'Drugstore', 'value': 'Drugstore'},
    {'label': 'Grocery', 'value': 'Grocery'},
    {'label': 'OOH', 'value': 'OOH'},
    {'label': 'Others - OOH', 'value': 'Others - OOH'},
    {'label': 'Others - Retails', 'value': 'Others - Retails'},
    {'label': 'Sari-Sari Store', 'value': 'Sari-Sari Store'},
    {'label': 'School', 'value': 'School'},
    {'label': 'Street Selling', 'value': 'Street Selling'},
    {'label': 'Sub-Distributor', 'value': 'Sub-Distributor'},
    {'label': 'Supermarket', 'value': 'Supermarket'},
    {'label': 'Foodservice', 'value': 'Foodservice'},
    {'label': 'Others', 'value': 'Others'}
  ];

  const handleChannelChange = (e, selectValue) => {
    if (selectValue) {
      let channel = selectValue.value;
      setEditData((prevAccount) => ({
        ...prevAccount, 
        channel: channel, 
        channel_array: selectValue, 
      }))
    }
  }

  return (
    <div>
      <Button 
        variant="contained" 
        size="small" 
        onClick={(e) => {handleOpen(e)}}
        sx={{
          background: '#4fed17', 
          color: '#fff',
          '&:hover': {
            background: 'rgba(54, 212, -2, 1)',  // Slightly darker shade for hover
          }
        }}
        >
      Update Channel
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Update Channel</DialogTitle>
        <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Customer" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                <Autocomplete
                    id="combo-box-demo"
                    onChange={handleChannelChange}
                    options={channel}
                    value={editData.channel_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                    renderInput={(params) => <TextField {...params} label="Channel*" />}
                  />
                </FormControl>
              </Grid>
            </Grid>
        </CustomTabPanel>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default UpdateChannel