import { Box, StyledEngineProvider } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddCustomerType from './AddCustomerType';
import EditCustomerType from './EditCustomerType';
import DeleteCustomerType from './DeleteCustomerType';


const CustomerTypes = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [customerType, setCustomerType] = useState([]);
  const [loading, setloading] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const columns = [
    { field: 'name', headerName: 'Name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      wrap: true,
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          <EditCustomerType customerTypeData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteCustomerType customerTypeData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    let ignore = false
    const getCustomerTypes = async (ignore) => {
      setloading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/CustomerTypes/getAllTypes.json',
        }
        const response = await axios(options);
        
        const data = response.data;
        if(!ignore) {
          setCustomerType(data)
          setloading(false)
        }
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. There's an error from the server. Please contact the provider.`);
      } finally {
        if(!ignore) {
          setloading(false)
        }
      }
    }

    getCustomerTypes(ignore);

    return () => {
      ignore = true
    }
  
  }, [onEditOrDelete]);
  
  return (
    <>
    <Box sx={{ marginBottom: '20px' }}>
      {/* add users button */}
      <AddCustomerType setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        disableRowSelectionOnClick
        rows={customerType}
        columns={columns}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default CustomerTypes