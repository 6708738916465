import { Box, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddRepairStatus from './AddRepairStatus';
import EditRepairStatus from './EditRepairStatus';
import DeleteRepairStatus from './DeleteRepairStatus';



const RepairStatus = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: 'code', headerName: 'Code',flex: 1, minWidth: 150, wrap: true  },
    { field: 'name', headerName: 'Name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      wrap: true,
      renderCell: (params) => (
        <>
          <EditRepairStatus repairStatusData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteRepairStatus repairStatusData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </>
      ),
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setIsLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/RepairStatuses/getAllRepairStatus.json',
        }
        const response = await axios(options);
        const data = response.data;
        if (data) {
          setAssetTypes(data)
        }
        
        setIsLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEditOrDelete]);
  return (
    <>
    <Box sx={{ marginBottom: '20px' }}>
      <AddRepairStatus setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight 
        rows={assetTypes}
        columns={columns}
        loading={isLoading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default RepairStatus