import { Close, CloudUpload, Edit } from '@mui/icons-material';
import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import useAuth from '../../hooks/UseAuth';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function EditRepairRequest({repairDetails, setonRejectOrApprove}) {
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [repairRequests, setRepairRequests] = useState({
    asset: null,
    asset_code: '',
    asset_name: '',
    servicesSelected: [],
    services: [],
    image_before_repair: [],
    is_major_repair: '',
    remarks: '',
    id: '',
    request_code: ''
  });

  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [openService, setOpenService] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);


  const handleOpen = () => {
    setOpen(true)

    const {is_major_repair, asset_code, asset_name, remarks, id, request_code} = repairDetails;
    setRepairRequests((prevData) => ({ 
      ...prevData, 
      is_major_repair: is_major_repair,
      remarks: remarks,
      asset_code: asset_code,
      asset_name: asset_name,
      id: id,
      request_code: request_code,
      asset: {'label' : asset_code, value: asset_code},
    }))
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setRepairRequests({
      asset: [],
      asset_code: '',
      asset_name: '',
      servicesSelected: [],
      services: [],
      image_before_repair: [],
      is_major_repair: '',
      remarks: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setShowProgress(true);
    try {
        const formData = new FormData();
        if (repairRequests.image_before_repair.length >  0) {
          repairRequests.image_before_repair.forEach((file, index) => {
            formData.append(`image_before_repair_${index}`, file);
          });
        }

        // Add other fields to formData if needed
        formData.append('asset_code', repairRequests.asset_code);
        formData.append('asset_name', repairRequests.asset_name);
        formData.append('is_major_repair', repairRequests.is_major_repair);
        formData.append('remarks', repairRequests.remarks);
        formData.append('services', repairRequests.services);
        formData.append('id', repairRequests.id);
        formData.append('request_code', repairRequests.request_code);

        const options = {
            method: 'POST',
              url: '/ShopRepairs/UpdateRepair.json',
              data: formData,
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForRepairs.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getServices= async(ignore) => {
    try {
      setServiceLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetServices/getAllServicesForAutoComplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setServices(response.data);
        }
        setServiceLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  useEffect(() => {
    let ignore = false;

    if (!openService) return undefined
    getServices(ignore)

    return () => {
      ignore = true
    };
  }, [openService]);

  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      return setRepairRequests(
        (prevAccount) => ({ ...prevAccount, asset: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name})
      )
    } 

    setRepairRequests((prevAccount) => ({ ...prevAccount, asset: []}))
  }
  const hanldeServicesChange = (e, selectValue) => {
    if (selectValue) {
      let services = selectValue.map(item => item.service_code);
      return setRepairRequests((prevAccount) => ({ ...prevAccount, servicesSelected: selectValue,  services: services}))
    } 
    
    setRepairRequests((prevAccount) => ({ ...prevAccount, servicesSelected: [], services: []}))
  }

  const hanldeMajorRepair = (e) => {
    setRepairRequests((prevAccount) => ({ ...prevAccount, is_major_repair: e.target.value }))
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRepairRequests((prevAccount) => ({ ...prevAccount, image_before_repair: [...prevAccount.image_before_repair, ...files]}))
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = repairRequests.image_before_repair.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRepairRequests(prevState => ({
      ...prevState,
      image_before_repair: updatedImages
    }));
  };
  return (
    <>
    <Button 
      variant='contained' 
      size="small" 
      color='info' 
      style={{ marginRight: '8px' }}  
      startIcon={<Edit />}
      disabled={repairDetails.status === 4 || repairDetails.status === 1 || repairDetails.status === 5 || repairDetails.status === 3 ? true : false}
      onClick={handleOpen}
    >Edit
    </Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Update Repair request</DialogTitle>
      <DialogContent>
          <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openAssets}
                      onOpen={() => {setOpenAssets(true);}}
                      onClose={() => {setOpenAssets(false);}}
                      loading={loading}
                      onChange={hanldeAssetsChange}
                      options={assets}
                      value={repairRequests.asset}
                      noOptionsText={'No Available Data'}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Asset for Evaluation"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="is-major-repair">Major repair?</InputLabel>
                <Select
                  labelId="is-major-repair"
                  value={repairRequests.is_major_repair}
                  label="Select value"
                  onChange={hanldeMajorRepair}
                >
                <MenuItem value={0}>No</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item xs={12}>
            <Typography mt={2} sx={{color: '#b60b2f'}}><i>Note: select services to replace the current service</i></Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      disableCloseOnSelect
                      multiple
                      open={openService}
                      onOpen={() => {setOpenService(true);}}
                      onClose={() => {setOpenService(false);}}
                      onChange={hanldeServicesChange}
                      options={services}
                      value={services.label}
                      loading={serviceLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Service"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {serviceLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} mb={2}>
              <Typography mt={2} sx={{color: '#b60b2f'}}><i>Note: upload new photo to replace  the existing one.</i></Typography> 
              <Typography mt={1}>Photo before repair:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {repairRequests.image_before_repair.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {repairRequests.image_before_repair.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
            {(Number(auth.role) === 2 || Number(auth.role) === 0)  && <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input Remarks" 
                  onChange={(e) => setRepairRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
            </Grid>}
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setonRejectOrApprove((prev) => !prev)
            }}
            message={message}
          />
      </StyledEngineProvider>

    </>
  )
}

export default EditRepairRequest