import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, styled, StyledEngineProvider } from '@mui/material';
import React, { useState } from 'react'
import { CloudUpload } from '@mui/icons-material';
import { AxiosCustomConfig } from '../../../config/AxiosCustomConfig';
import { objectToFormData } from '../../GenericFunctions/GenericFunctions';
import ProcessDialog from '../../Utils/ProcessDialog';
import MessageDialog from '../../Utils/MessageDialog';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function AddTapsBtn({setDoneUpload}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const [addData, setAddData] = useState({

    blank_file: '',
    file_name: '',
    file_type: ''

  });

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!addData.blank_file) {
      setOpenMessageDialog(true);
      setMessage('Please upload blank TAPS document')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/RequestBlankFiles/addBlankFiles.json',
              data: objectToFormData(addData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setDoneUpload((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setAddData({
      blank_file: '',
      file_name: '',
      file_type: '',
    });
  };

  const handleTAPSChange = (event) => {
    const file = event.target.files[0];
    setAddData((prev) => ({...prev, blank_file: file,file_name: 'blank taps file' , file_type: 'blank_taps_file'}));
  };

  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Add Blank taps</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add Blank Taps</DialogTitle>
      <DialogContent>
      <Grid container item xs={12} alignItems="center" spacing={2} >
          <Grid item>
            <Button component="label" variant="contained" startIcon={<CloudUpload />}>
              Upload Blank TAPS
              <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleTAPSChange}/>
            </Button>
          </Grid>
          <Grid item>
            {addData.blank_file && (
              <p>{addData.blank_file.name}</p>
            )}
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddTapsBtn