import { Box, StyledEngineProvider } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddViaCsv from './AddViaCsv';
import AddCustomerChain from './AddCustomerChain';
import EditCustomerChain from './EditCustomerChain';
import DeleteCustomerChain from './DeleteCustomerChain';


const CustomerChains = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [customerType, setCustomerType] = useState([]);
  const [loading, setloading] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const columns = [
    { field: 'chain_code', headerName: 'Chain code',flex: 1, minWidth: 150, wrap: true  },
    { field: 'chain_name', headerName: 'Chain name',flex: 1, minWidth: 250,},
    { field: 'customer_group', headerName: 'Customer Group',flex: 1, minWidth: 150,},
    { field: 'customer_type', headerName: 'Customer Type',flex: 1, minWidth: 150,},
    { field: 'is_active', headerName: 'Active', flex: 1, minWidth: 150,
      renderCell: (params) => {
        let is_active = Number(params.value) === 1 ? 'Yes' : 'No'
        return is_active
      },
    },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      wrap: true,
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          <EditCustomerChain customerChainData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteCustomerChain customerChainData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    let ignore = false
    const getCustomerTypes = async (ignore) => {
      setloading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/CustomerChains/getAllCustomerChains.json',
        }
        const response = await axios(options);
        
        const data = response.data;
        if(!ignore) {
          setCustomerType(data)
          setloading(false)
        }
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. There's an error from the server. Please contact the provider.`);
      } finally {
        if(!ignore) {
          setloading(false)
        }
      }
    }

    getCustomerTypes(ignore);

    return () => {
      ignore = true
    }
  
  }, [onEditOrDelete]);
  
  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AddCustomerChain setOnEditOrDelete={setOnEditOrDelete}/>
        <AddViaCsv setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        disableRowSelectionOnClick
        rows={customerType}
        columns={columns}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default CustomerChains