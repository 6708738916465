import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Papa from 'papaparse';
import CSVTemplateDownloadButton from '../CSVTemplateDownloadButton';
import MessageDialog from '../Utils/MessageDialog';
import { FileUpload } from '@mui/icons-material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CsvStatusDialog from '../Utils/CsvStatusDialog';

const AddViaCsv = ({setOnEditOrDelete}) => {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const [csvData, setCSVData] = useState(null); // State to store parsed CSV data
  const [csvUploadPercent, setCsvUploadPercent] = useState(0);
  const [uploadStatusArray, setUploadStatusArray] = useState([]);
  const [doneUploadingProduct, setDoneUploadingProduct] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [showCsvStatusDialog, setShowCsvStatusDialog] = useState(false);
  const [showUploadStatusDialog, setShowUploadStatusDialog] = useState(false);
  const fileInputRef = useRef(null);

  const objectToFormData = (object) => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());


  const handleCancel = () => {
    setOpen(false);
    setCSVData(null)
  };

  const onInputChange = (event) =>{
    let file = event.target.files;
    setCSVData(file);
  };

  const generateCSVContent = () => {
    // Generate CSV content with default header
    const header = "Customer Chain*,Customer Chain Description,Customer Group,Customer Type*,Active*";
    return header;
  };

  const handleClose = () => {
    setDoneUploadingProduct(false);
    setOpenMessageDialog(false);
    setOnEditOrDelete(prev => !prev);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const processUploadProductsCsv = async (csvData) => {
    if (csvData && csvData.length > 0) {
      try {
        const file = csvData[0];
        if( file.type !== "text/csv"){
          setOpenMessageDialog(true);
          setMessage('File error. The file must be a csv.');
          return;
        }

        const customerChainList =  await readCsv(file);

        if(customerChainList.length <=0){
          setOpenMessageDialog(true);
          setMessage("CSV must not empty.");
          return;
        }

        if(customerChainList.success !== true){
          setOpenMessageDialog(true);
          setMessage(customerChainList.message);
          return;
        }

        const chunkedData = await chunkUploadData(customerChainList.data);
        const totalData = Math.ceil(customerChainList.data.length / 200);

        setModalTitle('Upload Customer chains');
        setShowCsvStatusDialog(true)
        insertProducts(0,chunkedData,totalData);

      } catch(error){
        setOpenMessageDialog(true);
        setMessage(error);
      }   
    } else {
      setMessage('File error. Please select csv file.')
      setOpenMessageDialog(true);
    }
  };

  function readCsv(csvFile) {
    return new Promise((resolve, reject) => {
      var listData = [];
      let has_error = 0;
      let return_message = {
        success: false,
        message: '',
        data: []
      }
          Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: false,
            encoding: "ISO-8859-3",
            step:function(row, parser){
              let headers = generateCSVContent().split(',');

              let mismatchedHeaders = [];
              if (headers.length === row.meta.fields?.length) {
                for (var h = 0; h < row.meta.fields?.length; h++) {
                  let csv_header = row.meta.fields[h].trim();
    
                  if (!headers.includes(csv_header)) {
                    has_error++;
                    mismatchedHeaders.push({ incorrect: csv_header, correct: headers[h] });
                  }
                }

                if (mismatchedHeaders.length > 0) {
                  return_message.message = 'Invalid Headers:\n';
                  mismatchedHeaders.forEach(header => {
                    return_message.message += `Expected "${header.correct}", but found "${header.incorrect}".\n`;
                  });
                  parser.abort();
                }

                if(row.errors.length === 0){
                  var customerChainData = {};
                  customerChainData = {
                    chain_code: row.data.hasOwnProperty('Customer Chain*') ? row.data['Customer Chain*'].trim() : row.data['Customer Chain*'],
                    chain_name: row.data.hasOwnProperty('Customer Chain Description') ? row.data['Customer Chain Description'].trim() : row.data['Customer Chain Description'],
                    customer_group: row.data.hasOwnProperty('Customer Group') ? row.data['Customer Group'] : row.data['Customer Group'],
                    customer_type: row.data.hasOwnProperty('Customer Type*') ? row.data['Customer Type*'].trim() : row.data['Customer Type*'],
                    active: row.data.hasOwnProperty('Active*') ? row.data['Active*'] : row.data['Active*']
                  };
                  listData.push(customerChainData);
                  parser.resume()
                }
              } else {
                has_error++;
                return_message.message = 'Header Count are not match';
                parser.abort();
              }
            },
            complete: function (results) {
              if (has_error === 0) {
                return_message.success = true;
                return_message.data = listData;
              } else {
                return_message.success = false;
              }
              resolve(return_message)
            },
            error: function (error) {
              reject(error);
            },
          });
        });
  };

  function chunkUploadData(results){
    let chunkedData = [];
    let dataArray = [];
    for(let i = 0; i < results.length; i++){
      if(dataArray.length === 200){
        chunkedData.push(dataArray);
        dataArray = [];
      }

      dataArray.push(results[i]);
   
      if ( i === results.length - 1 )
      {
        chunkedData.push(dataArray);
        dataArray = [];
      }
    }
    return chunkedData;
  };

  async function insertProducts(index, chunkedData, totalData){
    let percent = (index / totalData) * 100;
    setCsvUploadPercent((prevProgress) => (prevProgress >= 100 ? percent : percent));
    if(index < chunkedData.length){
      var data = JSON.stringify(chunkedData[index]);
      await axios({
        method: 'POST',
        url: '/CustomerChains/uploadCustomerChainMasterFile.json',
        data: objectToFormData({index:index, data:data}),
        dataType: 'json' // Add this line
      }).then((res) =>{
          let res_status = res.data;

          if (Array.isArray(res_status) && res_status.length !== 0) {
            setUploadStatusArray(uploadStatusArray => [...uploadStatusArray, ...res_status]);
          }

          insertProducts(index+1, chunkedData, totalData);
        
      }).catch(error=>{
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);

      });
    }else{
      setDoneUploadingProduct(prev => !prev);
      setOpen(false)
    }
  }

  useEffect(()=>{
    if(doneUploadingProduct === true && uploadStatusArray.length <=0){
        setMessage("Customer chains uploaded successfully.")
        setOpenMessageDialog(true);
        setOnEditOrDelete(prev => !prev)
        closeUploadStatusDialog();
    }
  },[doneUploadingProduct])

  function closeUploadStatusDialog() {
    setShowCsvStatusDialog(false)
    setOpen(false);
    setCSVData(null)
  }

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Add via CSV</Button>
      
      <Dialog fullWidth={true} maxWidth={'md'} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Upload Customer Chains</DialogTitle>
        <DialogContent>
        <Box p={1} display="flex" justifyContent="left"  flexDirection="column"><i>
          1. All fields with asterisk are required <br/></i> <br/>
          
          <input accept=".csv" type="file" ref={fileInputRef} onChange={onInputChange}/>
        </Box>
        </DialogContent>
        <DialogActions>
        <CSVTemplateDownloadButton csvContent={generateCSVContent()} filename="Customer chains Master File.csv" />
        <Button variant="contained" color='success' component="label" onClick={() => {processUploadProductsCsv(csvData)}}><FileUpload /> Upload
        </Button>
        <Button color="primary" onClick={handleCancel} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
      <CsvStatusDialog 
        modalTitle={modalTitle} 
        showCsvStatusDialog={showCsvStatusDialog}
        csvUploadPercent={csvUploadPercent}
        uploadStatusArray={uploadStatusArray}
        handleClose ={() => {
          setCSVData(null)
          setShowCsvStatusDialog(false)
          setUploadStatusArray([])
          }}
      />
      <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={handleClose}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default AddViaCsv;