import {Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Paper, StyledEngineProvider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import {objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';

import { useTheme } from '@emotion/react';
import { format } from 'date-fns';
import useAuth from '../../hooks/UseAuth';
import useCommentsContext from '../../hooks/UseCommentsContext';
const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);

function CloseAuditAsset({auditAssets, setOnEditOrDelete}) {
  const {auth} = useAuth();
  const theme = useTheme();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const [auditAsset, setAuditAsset] = useState({
    asset_code: '',
    id: '',
    unique_code: '',
  });

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const {id, asset_code,unique_code} = auditAssets;
    setAuditAsset({id, asset_code,unique_code});
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AuditAssets/closeAudit.json',
              data: objectToFormData(auditAsset),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setAuditAsset({
      asset_code: '',
      id: '',
    });
  };

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  return (
    <div>
    <Button 
      variant="outlined" 
      size="small" 
      sx={{ marginRight: '10px'}} 
      color='success'
      onClick={(e) => {handleOpen(e)}}>
      View
      </Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Audit asset details</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={1} style={{ height: '100%' }}>
                <Typography p={2}>Info</Typography>
                  <StyledTable sx={{ width: '100%' }} size="small">
                      <TableHead>
                      </TableHead>
                      <TableBody>
                      {Object.entries(auditAssets)
                        .filter(([key, _]) => key !== 'deleted' && key !== 'status' && key !== 'unique_code')
                        .map(([key, value]) => (
                        <TableRow key={key}>
                          <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                          <TableCell style={key === 'status' ? { backgroundColor: value === '0' ? 'blue' : value === '1' ? 'orange' : 'inherit' } : {}}>
                            {key === 'status' ? (
                                value === 0 ? <Chip label='Processing' color='info' /> : 
                                value === 1 ? <Chip label='Closed' color='success' />:
                                ''
                            ) : key === 'created' ? (
                                value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                            ) : key === 'modified' ? (
                                value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                            ) : key === 'date_audited' ? (
                                value !== null && value !== "" ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                            ) : key === 'asset_image_name' ? (
                                value !== '' ? <img
                                  src={value}
                                  alt='after repair'
                                  style={{ width: 100, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                                /> : ''
                            ) : key === 'asset_no_image_name' ? (
                                value !== '' ? <img
                                  src={value}
                                  alt='after repair'
                                  style={{ width: 100, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                                /> : ''
                            ) : key === 'branch_image_name' ? (
                                value !== '' ? <img
                                  src={value}
                                  alt='after repair'
                                  style={{ width: 100, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                                /> : ''
                            ) : (
                                value
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      </TableBody>
                  </StyledTable>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button disabled={auditAssets.status === 1 || Number(auth.role) === 4 ? true : false} variant="contained" size="small" color='success' onClick={handleSubmit}>Close audit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default CloseAuditAsset