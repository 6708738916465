import { Box, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddModelsViaCsv from './AddModelsViaCsv';
import AddAssetModels from './AddAssetModels';
import EditModels from './EditModels';
import DeleteModel from './DeleteModel';




const AssetModels = () => {
  const axios = AxiosCustomConfig();
  const [users, setUsers] = useState([]);
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [assetBrands, assetModels] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'model', headerName: 'Asset Model',flex: 1, minWidth: 250, wrap: true  },
    { field: 'asset_type_size', headerName: 'Asset type size',flex: 1, minWidth: 200, wrap: true  },
    { field: 'asset_brand', headerName: 'Asset Brand',flex: 1, minWidth: 200, wrap: true  },
    { field: 'external_dimensions', headerName: 'External Dimensions',flex: 1, minWidth: 200, wrap: true  },
    { field: 'internal_dimensions', headerName: 'Internal Dimensions',flex: 1, minWidth: 200, wrap: true  },
    { field: 'capacity_gal', headerName: 'Capacity (gal)',flex: 1, minWidth: 100, wrap: true  },
    { field: 'gross_capacity', headerName: 'Gross Capacity',flex: 1, minWidth: 100, wrap: true  },
    { field: 'net_capacity', headerName: 'Net Capacity',flex: 1, minWidth: 100, wrap: true  },
    { field: 'capacity_in_cubic_ft', headerName: 'Capacity in cubic ft',flex: 1, minWidth: 100, wrap: true  },
    { 
      field: 'asset_area', 
      headerName: 'Asset Area', 
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      valueFormatter: (params) => {
        const value = Number(params.value);
        return value.toFixed(2);
      },
    },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 100,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      wrap: true,
      renderCell: (params) => (
        <>
        <EditModels modelData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        <DeleteModel modelData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
        </>
      ),
    },
  ];

  useEffect(() => {
    const getassetBrands = async () => {
      try {
        setLoading(true)
        const options = {
          'method': 'POST',
          'url' : '/AssetModels/getAssetModels.json',
        }
        const response = await axios(options);
        const data = response.data;

        assetModels(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getassetBrands();
  }, [onEditOrDelete]);
  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', gap: '10px' }}>
      <AddAssetModels setOnEditOrDelete={setOnEditOrDelete}/>
      <AddModelsViaCsv setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight
        rows={assetBrands}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        loading={loading}
        pageSizeOptions={[10, 25, 50, 100]}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default AssetModels