import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Apps, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/UseAuth';
import { useTheme } from '@emotion/react';
import { alpha } from '@mui/material';

const filterRoutesByRole = (routes, role) => {
  // Convert role to a number if it’s a string
  const roleNumber = Number(role);

  const filterItems = (items) => {
    return items.map(item => {
      // Convert roles in route to numbers for comparison
      const itemRoles = item.role.map(r => Number(r));
      
      if (item.subItems) {
        const filteredSubItems = item.subItems.filter(subItem => subItem.role.map(r => Number(r)).includes(roleNumber));
        if (item.text === 'Setup' && item.subItems) {
          const setupSubItems = item.subItems.map(setupSubItem => {
            const otherSubitem = setupSubItem.role.includes(roleNumber);

            if(otherSubitem) {
              if (setupSubItem.text === 'Assets') {
                const assetSubItems = setupSubItem.subItems.filter(assetSubItem => {
                  return assetSubItem.role.map(r => Number(r)).includes(roleNumber) || assetSubItem.path === '/asset/registry';
                });
  
                return {
                  ...setupSubItem,
                  subItems: assetSubItems
                };
              }
            }

            return otherSubitem ? setupSubItem : null;
          }).filter(Boolean);

          return {
            ...item,
            subItems: setupSubItems
          };
        }
        
        if (filteredSubItems.length > 0) {
          return {
            ...item,
            subItems: filteredSubItems
          };
        } else if (itemRoles.includes(roleNumber)) {
          return { ...item, subItems: [] };
        } else {
          return null;
        }
      } else {
        return itemRoles.includes(roleNumber) ? item : null;
      }
    }).filter(Boolean);
  };

  return filterItems(routes);
};

const NavigationMenu = ({ routes, iconMap }) => {
  const {auth, setAuth} = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [openMenus, setOpenMenus] = useState({});
  const [filteredRoutes, setFilteredRoutes] = useState([]);

  useEffect(() => {
    if (auth) {
      const filtered = filterRoutesByRole(routes, auth.role);
      setFilteredRoutes(filtered);
    } else {
      setFilteredRoutes([]);
    }
  }, [auth, routes]);

  const handleToggle = (menu) => {
    setOpenMenus((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  const handleNavigation = (path) => {
    if (path === '/login') {
      // Clear localStorage
      localStorage.removeItem('authToken');
      localStorage.removeItem('userRole');
      
      // Optionally clear other stored items
      localStorage.clear();
      
      // Optionally clear auth context or state
      setAuth(null);
    }
    navigate(path);
  };

  const isSubItemActive = (subItems, pathname) => {
    return subItems.some(subItem => subItem.path === pathname);
  };

  const renderMenuItems = (items, level = 0) => {
    return items.map((item) => {
      const isActive = location.pathname === item.path;
      const isAnySubItemActive = item.subItems ? isSubItemActive(item.subItems, location.pathname) : false;

      return (
        <React.Fragment key={item.text}>
          <ListItemButton
            onClick={() => item.subItems ? handleToggle(item.text) : handleNavigation(item.path)}
            sx={{
              pl: level * 4,
              paddingLeft: level === 0 ? 3 : 4,
              backgroundColor: isActive || isAnySubItemActive ? alpha(theme.palette.primary.main, 0.2) : 'transparent',
            }}
          >
            <ListItemIcon>{React.createElement(iconMap[item.icon] || Apps)}</ListItemIcon>
            <ListItemText primary={item.text} primaryTypographyProps={{ fontSize: '0.875rem' }} />
            {item.subItems && (openMenus[item.text] ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {item.subItems && (
            <Collapse in={openMenus[item.text]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.subItems, level + 1)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <List>
      {renderMenuItems(filteredRoutes)}
    </List>
  );
};

export default NavigationMenu;
