import { Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ProcessDialog from './ProcessDialog';
import MessageDialog from './MessageDialog';
import { PictureAsPdf, Print } from '@mui/icons-material';
import { PDFDocument , rgb , StandardFonts  } from 'pdf-lib';

function TadrPrint({ requestDetails }) {
  
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');
  const [noTadr, setNoTadr] = useState(false);
  const [pdfContent, setPdfContent] = useState(null); // To store the filled PDF

  useEffect(() => {
    const fillPdfForm = async () => {
      try {
        const fileExtension = requestDetails.blank_tadr_display.split(';')[0].split('/')[1];
        
        setFileType(fileExtension === 'pdf' ? 'pdf' : 'image');
        setContent(requestDetails.blank_tadr_display);
        setNoTadr(false);
        
        if (fileExtension === 'pdf') {

          const pdfDoc = await PDFDocument.load(requestDetails.blank_tadr_display);
          const pages = pdfDoc.getPages();
          const firstPage = pages[0];
          // Get the form from the PDF
          if(requestDetails.account_code || requestDetails.destination_account_code || requestDetails.warehouse_code){
            const account_code = requestDetails.account_code 
                ? requestDetails.account_code 
                : requestDetails.destination_account_code 
                    ? requestDetails.destination_account_code 
                    : requestDetails.warehouse_code;
            firstPage.drawText(account_code, {
              x: 144,  // X coordinate (adjust based on your PDF layout)
              y: 978,  // Y coordinate (adjust based on your PDF layout)
              size: 12,  // Font size
              color: rgb(0, 0, 0),  // Text color (black)
            });

          }
          
          if(requestDetails.account_name || requestDetails.destination_account_name || requestDetails.destination_warehouse_name){
            const account_name = requestDetails.account_name ? requestDetails.account_name :requestDetails.destination_account_name ? requestDetails.destination_account_name 
            : requestDetails.destination_warehouse_name;
            firstPage.drawText(account_name, {
              x: 144,  // X coordinate (adjust based on your PDF layout)
              y: 949,  // Y coordinate (adjust based on your PDF layout)
              size: 12,  // Font size
              color: rgb(0, 0, 0),  // Text color (black)
            });

          }
         
          if (requestDetails.address || requestDetails.business_address || requestDetails.destination) {
            // Build the address string dynamically, filtering out empty or undefined values
            const addressParts = [
                requestDetails.business_address,
                requestDetails.address
            ].filter(part => part && part.trim() !== '');
        
            // Join the parts with a comma only if both exist
            let address = addressParts.join(', ');
            if(requestDetails.destination){

               address = requestDetails.destination;

            }
            
            // Draw the final address on the PDF
            drawWrappedText(firstPage, address, 169, 925, 240, 10); // Adjust the maxWidth accordingly

          }

          if (requestDetails.contact || requestDetails.branch_contact_number) {

            const contact = requestDetails.branch_contact_number ? requestDetails.branch_contact_number :requestDetails.contact;
            drawWrappedText(firstPage, contact, 545 , 974, 300, 12); // Adjust the maxWidth accordingly
          
          }

          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const day = String(today.getDate()).padStart(2, '0');
          
          const currentDate = `${year}-${month}-${day}`;
          
          drawWrappedText(firstPage, currentDate  , 594 , 946, 300, 12); // Adjust the maxWidth accordingly

          

          
          if(requestDetails.branch_name || requestDetails.destination_branch_name){

            const branch_name = requestDetails.branch_name ? requestDetails.branch_name : requestDetails.destination_branch_name
            drawWrappedText(firstPage, branch_name, 645 , 920, 300, 12); // Adjust the maxWidth accordingly

          }

          if(requestDetails.model){

            drawWrappedText(firstPage, requestDetails.model, 445 , 826, 300, 12); 
            
          }

          if(requestDetails.deployment_type_name || requestDetails.deployment_type){

            const deployment_type = requestDetails.deployment_type_name ? requestDetails.deployment_type_name : requestDetails.deployment_type
            drawWrappedText(firstPage, deployment_type, 74 , 828, 300, 12); // Adjust the maxWidth accordingly

          }

          if(requestDetails.asset_name){

            drawWrappedText(firstPage, requestDetails.asset_name, 434 , 828, 300, 12);

          }

          if(requestDetails.remarks){

            drawWrappedText(firstPage, requestDetails.remarks, 160 , 748, 300, 12);

          }

          if(requestDetails.approver || requestDetails.approver_name){

            const approver = requestDetails.approver ? requestDetails.approver : requestDetails.approver_name
            drawWrappedText(firstPage, approver, 414 , 127, 300, 14);

          }

          if(requestDetails.signature_image_convert){
            // Fetch the image from the AWS URL
            const response = await fetch(requestDetails.signature_image_convert);
            const imageBytes = await response.arrayBuffer(); // Get the image as ArrayBuffer
          
            // Embed the image in the PDF
            const signatureImage = await pdfDoc.embedPng(imageBytes); // Use embedJpg if it's a JPG image
          
            // Set the dimensions and position of the image
            const { width, height } = signatureImage.scale(0.5); // Scale as needed (adjust 0.5 to your desired size)
          
            // Draw the image at the specified coordinates (adjust as necessary)
            firstPage.drawImage(signatureImage, {
              x: 340, // X coordinate
              y: 100, // Y coordinate
              width: width,   // Set appropriate width (from scaling)
              height: height  // Set appropriate height (from scaling)
            });
          }

          if(requestDetails.signature_requestor_image_convert){

            
            const hauler = requestDetails.hauler ? requestDetails.hauler : '';

            if(hauler == ''){

              var x_value = 60;
              var y_value = 104;

            }else{

              var x_value = 560;
              var y_value = 104;

            }            

            // Fetch the image from the AWS URL
            const response = await fetch(requestDetails.signature_requestor_image_convert);
            const imageBytes = await response.arrayBuffer(); // Get the image as ArrayBuffer
          
            // Embed the image in the PDF
            const signatureImage = await pdfDoc.embedPng(imageBytes); // Use embedJpg if it's a JPG image
          
            // Set the dimensions and position of the image
            const { width, height } = signatureImage.scale(0.5); // Scale as needed (adjust 0.5 to your desired size)
          
            // Draw the image at the specified coordinates (adjust as necessary)
            firstPage.drawImage(signatureImage, {
              x: x_value, // X coordinate
              y: y_value, // Y coordinate
              width: width,   // Set appropriate width (from scaling)
              height: height  // Set appropriate height (from scaling)
            });
          }

          if(requestDetails.requestor_name){
            
            const hauler = requestDetails.hauler ? requestDetails.hauler : '';

            if(hauler == ''){

              var x_value = 94;
              var y_value = 118;

            }else{

              var x_value = 640;
              var y_value = 118;

            }  

            drawWrappedText(firstPage, requestDetails.requestor_name, x_value , y_value, 300, 14);

          }
          
          
          // Get all form fields
          
          const pdfBytes = await pdfDoc.save();
          const filledPdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
          const filledPdfUrl = URL.createObjectURL(filledPdfBlob);

          setPdfContent(filledPdfUrl);

        }
      } catch (error) {
        console.error("Error filling PDF form:", error);
        setNoTadr(true);
      }
    };

    if (requestDetails.blank_tadr_display) {
      fillPdfForm();
    } else {
      setContent('');
      setFileType('');
      setNoTadr(true);
    }
  }, [requestDetails]);

  const handleOpen = () => {
    setOpen(true);
    console.log(pdfContent);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrintPDF = async () => {
    if (pdfContent) {
      if (fileType === 'pdf') {
        // Directly download the PDF
        const link = document.createElement('a');
        link.href = pdfContent;
        link.download = 'tadr.pdf';
        link.click();
      } else if (fileType === 'image') {
        // Convert the image to a PDF and download it
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([600, 600]);
        
        const imageBytes = pdfContent.split(',')[1]; // Get the base64 string without the data URL prefix
        const imageBuffer = Uint8Array.from(atob(imageBytes), c => c.charCodeAt(0));
        const image = await pdfDoc.embedPng(imageBuffer);

        const { width, height } = page.getSize();
        page.drawImage(image, {
          x: 0,
          y: 0,
          width,
          height,
        });

        const pdfBytes = await pdfDoc.save();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));
        link.download = 'tadr.pdf';
        link.click();
      }
    }
  };

  const handlePrintWord = () => {
    if (pdfContent) {
      const link = document.createElement('a');
      link.href = pdfContent;
      link.download = 'tadr.docx';
      link.click();
    }
  };

  async function drawWrappedText(page, text, x, y, maxWidth, fontSize) {
    // Embed the Helvetica font (or use another Standard Font if needed)
    const font = await page.doc.embedFont(StandardFonts.Helvetica);
    
    const words = text.split(' ');
    let line = '';
    let lines = [];

    // Split the text into lines
    words.forEach((word) => {
        const testLine = line + word + ' ';
        const lineWidth = font.widthOfTextAtSize(testLine, fontSize);

        if (lineWidth > maxWidth) {
            lines.push(line); // Add the current line to lines array
            line = word + ' '; // Start a new line with the current word
        } else {
            line = testLine; // Continue adding words to the current line
        }
    });

    lines.push(line); // Push the final line

    // Draw each line on the page
    lines.forEach((lineText, index) => {
        page.drawText(lineText, {
            x,
            y: y - index * (fontSize + 5),  // Adjust Y for line height
            size: fontSize,
            font: font,
            color: rgb(0, 0, 0),  // Black text
        });
    });
}

  function formatDate(dateString) {

      const date = new Date(dateString);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      let day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      return `${year}-${month}-${day}`;

  }

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Print TADR</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Print TADR</DialogTitle>
        <DialogContent>
          {noTadr ? (
            <Typography variant="h6">No TADR available</Typography>
          ) : fileType === 'pdf' ? (
            <iframe
              src={pdfContent}
              title="PDF"
              width="100%"
              height="500px"
            />
          ) : (
            <img
              src={pdfContent}
              alt="tadr"
              width="100%"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handlePrintPDF} 
            color="primary" 
            startIcon={<PictureAsPdf />}
            disabled={noTadr}
          >
            Print as PDF
          </Button>
          <Button 
            onClick={handlePrintWord} 
            color="secondary" 
            startIcon={<Print />}
            disabled={noTadr}
          >
            Print as Word
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={false} ContentText="Loading Please Wait..." />
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={false}
          handleClose={() => {}}
          message=""
        />
      </StyledEngineProvider>
    </div>
  );
}

export default TadrPrint;
