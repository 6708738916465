
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs, } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import AssetDisposalList from './AssetDisposalList';
import DisposalImagesSlide from './DisposalImagesSlide';
import ApproveAssetDisposal from './ApproveAssetDisposal';
import DisposeScrapAsset from './DisposeScrapAsset';
import { AssetDisposalProvider } from '../../context/AssetDisposalProvider';
import FasrPrint from '../Utils/FasrPrint';
import MoreOptionBtn from './MoreOptionBtn';

const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);
const AssetDisposalDetails = () => {
  const { requestCode } = useParams();
  const decodedRequestCode = decodeURIComponent(requestCode);
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [onRejectOrApprove, setonRejectOrApprove] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [disposalDetails, setDisposalDetails] = useState({});
  // const [assetCode, setAssetCode] = useState([]);
  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/AssetsDisposal/getAllDisposalDetails.json',
          'data': {'request_code': decodedRequestCode}
      }
      const response = await axios(options);
      const data = response.data;
      setDisposalDetails(data);
      // setAssetCode(data.asset_code)
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [onRejectOrApprove]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const disposalImages = Object.entries(disposalDetails)
    .filter(([key, _]) => key.startsWith('disposal_image_'))
    .map(([_, value]) => value);

  return (
    <AssetDisposalProvider>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/assetDisposal"
          >
            Asset Disposal
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap: '8px' }}>
                    {/* <EditRepairRequest disposalDetails={disposalDetails} setonRejectOrApprove={setonRejectOrApprove} /> */}
                    {Number(auth.role) === 6 || Number(auth.role) === 0 ? (
                      <>
                        <DisposeScrapAsset disposalDetails={disposalDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                      </>
                    ) : ''}
                    
                    {Number(auth.role) !== 6 && (
                      <>
                        <ApproveAssetDisposal disposalDetails={disposalDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                      </>
                    )}

                    {Number(auth.role) === 6 || Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0 || Number(auth.role) === 3 ? (
                      <>

                        <FasrPrint requestDetails={disposalDetails} />
                        <MoreOptionBtn disposalDetails={disposalDetails} setonRejectOrApprove={setonRejectOrApprove}/>
                        
                      </>
                    ): ''}
                    
                  </Paper>
              </Grid>
              <Grid item>
                <Paper elevation={1} style={{ height: '100%', padding: '8px' }}>
                  <DisposalImagesSlide disposalImages={disposalDetails && disposalImages}/>
                </Paper>
              </Grid>
              
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%' }}>
                  <AssetDisposalList requestCode={requestCode}/>
                  </Paper>
              </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Info</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(disposalDetails)
                    .filter(([key, _]) => !key.startsWith('disposal_image_') && key !== 'disposal_asset_images' && key !== 'assets' && key !== 'blank_fasr_display' && key !== 'asset_details' && key !== 'signature_image_convert' && key !== 'signature_requestor_image_convert')
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell style={key === 'status' ? { backgroundColor: value === '0' ? 'blue' : value === '1' ? 'orange' : 'inherit' } : {}}>
                        {key === 'services' ? (
                              value.split(',').map((service, index) => (
                                  <div key={index}>{service}</div>
                              ))
                        ) : key === 'status' ? (
                            value === 0 ? <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} /> : 
                            value === 1 ? <Chip label='Approved to Scrap' sx={{ backgroundColor: '#D7D6D4' }} />: 
                            value === 2 ? <Chip label='Asset Disposed' sx={{ backgroundColor: '#3AD658' }} />: 
                            value === 3 ? <Chip label='For Completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />: 
                            value === 4 ? <Chip label='Closed' color='error' />: 
                            ''
                        ) : key === 'is_major_repair' ? (
                            value === 0 ? 'No' : 
                            value === 1 ? 'Yes': 
                            ''
                        ) : key === 'date_added' ? (
                            value !== null ? format(value, 'yyyy-MM-dd') : ''
                        ) : key === 'date_updated' ? (
                            value !== null ? format(value, 'yyyy-MM-dd') : ''
                        ) : key === 'date_approved' ? (
                            value !== null ? format(value, 'yyyy-MM-dd') : ''
                        ) : key === 'approver_signature' ? (
                            value !== '' ? <img
                              src={value}
                              alt='after repair'
                              style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                            /> : ''
                        ) : key === 'signature_of_requestor' ? (
                            value !== '' ? <img
                            src={value}
                            alt='after repair'
                            style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                          /> : ''
                      ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </AssetDisposalProvider>
  )
}

export default AssetDisposalDetails