import { Avatar, Box, Chip, StyledEngineProvider } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import AddAssets from './AddAssets';
import EditAssets from './EditAssets';
import DeleteAssets from './DeleteAssets';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddAssetsViaCsv from './AddAssetsViaCsv';
import ViewAssetBtn from './ViewAssetBtn';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import useFetchColumns from '../../hooks/UseFetchColumns';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import AssetFilters from '../AssetFilters/AssetFilters';
import useSortModel from '../../hooks/UseSortModel';

const AssetRegistry = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'assets';
  const modelTable = 'Assets';

  const [rowCount, setRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [filterData, setFilterData] = useState([]);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);
  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);

  const [sortModel, setSortModel] = useSortModel(columns);

  useEffect(() => {
    let isMounted = true;
    const getAssets = async (paginationModel, filterModel, sortModel, filterData) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getAllAssets.json',
          'data': {
            page: paginationModel.page, 
            pageSize: paginationModel.pageSize,
            filters: filterModel.items,
            sort: sortModel,
            db_filter: filterData,
          }
        }
        const response = await axios(options);
        
        if (isMounted) {
          const { data, totalCount } = response.data;
          setAssets(data);
          setRowCount(totalCount);
        }
      } catch (error) {
        if (isMounted) {
          setOpenMessageDialog(true);
          setMessage( `${error.toJSON().message}. Network error.`);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    getAssets(paginationModel, filterModel, sortModel, filterData);

    return () => {
      isMounted = false;
    };
  }, [onEditOrDelete, paginationModel, filterModel, sortModel, filterData]);

  const header_columns = [
    { field: 'image', headerName: 'Image name', width: 150,
      renderCell: (params) => (
        <Avatar alt="Avatar" src={params.value} />
      )
    },
    { field: 'code', headerName: 'Equipment No.', flex: 1, minWidth: 150, wrap: true,},
    { field: 'name', headerName: 'Asset name',flex: 2, minWidth: 150, wrap: true,  },
    { field: 'description', headerName: 'Description', flex: 2, minWidth: 150,},
    { field: 'asset_type_name', headerName: 'Asset Type',flex: 1, minWidth: 150,},
    { field: 'asset_status', headerName: 'Status',flex: 1, minWidth: 150,},
    { field: 'asset_model', headerName: 'Asset Model',flex: 1, minWidth: 150,},
    { field: 'asset_jde_no', headerName: 'Asset Jde No',flex: 1, minWidth: 150,},
    { field: 'store_name', headerName: 'Store/Business name',flex: 1, minWidth: 300,},
    { field: 'asset_type_size', headerName: 'Asset Type Size',flex: 1, minWidth: 150,},
    { field: 'audit_comment', headerName: 'Audit Comment',flex: 1, minWidth: 150,},
    { field: 'warehouse_branch', headerName: 'Warehouse Branch',flex: 2, minWidth: 150,},
    { field: 'store_address', headerName: 'Store Address',flex: 1, minWidth: 150,},
    { field: 'audit_period', headerName: 'Audit Period',flex: 1, minWidth: 150,},
    { field: 'audit_remarks', headerName: 'Audit Remarks',flex: 1, minWidth: 150,},
    { field: 'channel', headerName: 'Channel',flex: 1, minWidth: 150,},
    { field: 'contact_no', headerName: 'Contact No',flex: 1, minWidth: 150,},
    { field: 'contact_person', headerName: 'Contact Person',flex: 1, minWidth: 150,},
    { field: 'created_by', headerName: 'Created By',flex: 1, minWidth: 150,},
    { field: 'customer_type', headerName: 'Customer Type',flex: 1, minWidth: 150,},
    { field: 'distributor_customer_chain', headerName: 'Distributor Customer Chain',flex: 1, minWidth: 200,},
    { field: 'jde_syspro_customer_no', headerName: 'Jde Syspro Customer No',flex: 1, minWidth: 150,},
    { field: 'latest_asset_update', headerName: 'Latest Asset Update',flex: 1, minWidth: 150,},
    { field: 'region', headerName: 'Region',flex: 1, minWidth: 150,},
    { field: 'remarks', headerName: 'Remarks',flex: 1, minWidth: 150,},
    { field: 'serial_no', headerName: 'Remarks',flex: 1, minWidth: 150,},
    { field: 'tam_remarks', headerName: 'Tam Remarks',flex: 1, minWidth: 150,},
    {
      field: 'jde_system_status',
      headerName: 'JDE System Status',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const value = params.value;
    
        return value === 0 ? <Chip label="Active" color="success" /> : 
              value === 1 ? <Chip label="Deleted" color="error" /> : 
              value === 2 ? <Chip label="Inactive" color="warning" /> : 
              value === 3 ? <Chip label="For write-off" color="primary" /> : 
              null;
      },
    },
    { 
      field: 'date_scrapped', 
      headerName: 'Date Scrapped',
      flex: 1, 
      minWidth: 150,
      wrap: true 
    },
    { 
      field: 'scrap_date', 
      headerName: 'Date Scrapped',
      flex: 1, 
      minWidth: 150,
      wrap: true 
    },
    { field: 'created', headerName: 'Date Added',flex: 1, minWidth: 150, wrap: true },
    { field: 'modified', headerName: 'Date Updated',flex: 1, minWidth: 200,  wrap: true },
    { field: 'acquisition_date', headerName: 'Acquisition date',flex: 1, minWidth: 200,  wrap: true },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      wrap: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', gap: '5px' }}>
          <EditAssets assetsData={params.row} setOnEditOrDelete={setOnEditOrDelete} />
          <DeleteAssets assetsData={params.row} setOnEditOrDelete={setOnEditOrDelete} />
          <ViewAssetBtn assetsData={params.row} />
        </div>
      ),
    },
    {
      field: 'jde_system_status',
      headerName: 'Jde System Status',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const value = params.value;
        return value === 0 ? <Chip label="Active" color="success" /> :
               value === 1 ? <Chip label="Deleted" color="error" /> :
               value === 2 ? <Chip label="Inactive" color="warning" /> :
               value === 3 ? <Chip label="For write-off" color="primary" /> :
               null;
      }
    }
  ];

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/AssetsRegistry/getAllAssetIds.json',
          'data': {
            filters: filterModel.items,
            sort: sortModel,
            db_filter: filterData,
          }
        };
        const response = await axios(options);
        const allAssets = response.data.data.map((asset) => asset.id);
        setRowSelectionModel(allAssets);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }


  const handleRowSelectionModelChange = async (newSelection) => {
    setRowSelectionModel(newSelection);
  };

  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);

  const handleFilterChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  useEffect(() => {
    const getFilters = async () => {
      try {
        const options = {
          'method': 'POST',
          'url' : '/FilterSettings/getFilters.json',
          'data' : {function_name: functionName}
        }
        const response = await axios(options);
        
        const { data } = response.data;
          setFilterData(data);
      } catch (error) {
        setOpenMessageDialog(true);
          setMessage( `${error.toJSON().message}. Network error.`);
      } finally {
      }
    }

    getFilters();

  }, [onEditOrDelete]);

  useEffect(() => {
    setRowSelectionModel([])
    setSelectAll(false)
  }, [filterData]);
  
  return (
    <>
      <Box sx={{ marginBottom: '20px' }}>
        <AssetFilters filterData={filterData} setOnEditOrDelete={setOnEditOrDelete} columns={columns} setOnSaveColumns={setOnSaveColumns} functionName={functionName} paginationModel={paginationModel}/>
      </Box>
      <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Container for left-aligned buttons */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <AddAssets setOnEditOrDelete={setOnEditOrDelete} />
          <AddAssetsViaCsv setOnEditOrDelete={setOnEditOrDelete} />
        </Box>

        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Settings Icon and Menu */}
        {/* <Box sx={{ position: 'relative' }}>
          <ColumnSettingsButton
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            paginationModel={paginationModel}
          />
        </Box> */}
      </Box>
      <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
        <DataGrid
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: () => <CustomToolbar 
              selectedRows={rowSelectionModel} 
              columns={columns}
              setOnSaveColumns={setOnSaveColumns}
              functionName={functionName}
              modelTable={modelTable}
              handleClickAllData={handleClickAllData}
              selectAll={selectAll}
            />,
            footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>,
          }}
          pageSizeOptions={pageSizeOptions}
          rows={assets}
          columns={filteredAndOrderedColumns}
          checkboxSelection
          onRowSelectionModelChange={handleRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick
          pagination
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          rowCount={rowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          filterModel={filterModel}
          onFilterModelChange={handleFilterChange}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          sortingOrder={['asc', 'desc']}
          loading={loading}
          getRowHeight={() => 'auto'}
          autoHeight
          keepNonExistentRowsSelected
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '10px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '10px',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              opacity: 0.5, 
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }
          }}
        />
      </Box>
      <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </>
  )
}

export default AssetRegistry