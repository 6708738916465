import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import MFASettings from "./MFASettings/MFASettings";
import useAuth from "../../hooks/UseAuth";
import UploadBlankTadr from "./UploadBlankTadr/UploadBlankTadr";
import UploadBlankTaps from "./UploadBlankTaps/UploadBlankTaps";
import UploadBlankTapor from "./UploadBlankTapor/UploadBlankTapor";
import UploadBlankTalaf from "./UploadBlankTalaf/UploadBlankTalaf";
import UploadBlankTadrsc from "./UploadBlankTadrsc/UploadBlankTadrsc";
import UploadBlankTala from "./UploadBlankTala/UploadBlankTala";

export default function GeneralSettings() {
    const {auth} = useAuth();
    return (
        <Grid container spacing={2}>
            <Grid item>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                    <Typography variant="h6">MFA Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MFASettings />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item>
                {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0  ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                        <Typography variant="h6">TADR Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadBlankTadr />
                        </AccordionDetails>
                    </Accordion>
                ) : ''}
            </Grid>
            <Grid item>
                {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0  ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                        <Typography variant="h6">TAPS Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadBlankTaps />
                        </AccordionDetails>
                    </Accordion>
                ) : ''}
            </Grid>
            <Grid item>
                {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0 ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                        <Typography variant="h6">TAPOR Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadBlankTapor />
                        </AccordionDetails>
                    </Accordion>
                ) : ''}
            </Grid>
            <Grid item>
                {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0  ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                        <Typography variant="h6">TALAF Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadBlankTalaf />
                        </AccordionDetails>
                    </Accordion>
                ) : ''}
            </Grid>
            <Grid item>
                {Number(auth.role) === 2 || Number(auth.role) === 1 || Number(auth.role) === 0 ? (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                        <Typography variant="h6">TADRSC Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <UploadBlankTadrsc />
                        </AccordionDetails>
                    </Accordion>
                ) : ''}
            </Grid>
        </Grid>
    )
}