import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Close, CloudUpload } from '@mui/icons-material';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import ReactSignatureCanvas from 'react-signature-canvas';
import { isValidSpecialCharacter, trimWhitespaces } from '../GenericFunctions/GenericFunctions';
import { useTheme } from '@emotion/react';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function AddDisposalRequest({assetCode, setOnEdit}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const assetsLoading = openAssets && assets.length === 0;
  const [openSignature, setOpenSignature] = useState(false);
  const [disposalRequests, setDisposalRequests] = useState({
    assetSelected: [],
    assets: [],
    asset_image: [],
    remarks: '',
    store_contact_no: '',
    store_contact_name: '',
    signature_url: ''
    
  });

  const handleOpenSignature= () => {
    setOpenSignature(true)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const signatureRef = useRef();
  const handleClose = () => {
    setOpen(false)
  }
  const handleSaveSignature = () => {
    const dataURL = signatureRef.current.toDataURL('image/png');
    if (dataURL) {

        setDisposalRequests((prevData) => ({ ...prevData, signature_url: dataURL}))
        setOpenSignature(false);
    }
    
  };

  const handleAssetsChange = (e, selectValue) => {
    if (selectValue && selectValue.length > 0) {
        const firstBranch = selectValue[0].warehouse_branch;
        const lastSelected = selectValue[selectValue.length - 1];

        // Check if the last added item's branch matches the first branch
        if (lastSelected.warehouse_branch !== firstBranch) {
            // Remove the last selected item from selectValue
            selectValue = selectValue.slice(0, -1);
            console.log(selectValue);
            
            setOpenMessageDialog(true);
            setMessage('The last selected asset was removed because it belongs to a different warehouse branch.');
            return;
        }
        
        // Filter assets options to match only the first branch
        const filteredAssets = assets.filter(asset => asset.warehouse_branch === firstBranch);

        // Map the updated selectValue to get asset codes
        const assetCodes = selectValue.map(item => item.asset_code);

        // Update disposal requests and assets options
        setDisposalRequests(prevAccount => ({
            ...prevAccount,
            assetSelected: selectValue,
            assets: assetCodes
        }));
        setAssets(filteredAssets); // Update the Autocomplete options to show only matching branch assets
    } else {
        // Reset disposal requests and assets options when no items are selected
        setDisposalRequests(prevAccount => ({ ...prevAccount, assetSelected: [], assets: [] }));
        setAssets([]); // Reset options to initial assets list if needed
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setDisposalRequests((prevAccount) => ({ ...prevAccount, asset_image: [...prevAccount.asset_image, ...files]}))
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = disposalRequests.asset_image.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setDisposalRequests(prevState => ({
      ...prevState,
      asset_image: updatedImages
    }));
  };

  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForDisposal.json',
            'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        if (!ignore) {
          const filteredAssets = response.data.filter(asset => asset.warehouse_branch); // Filters out assets with a blank warehouse_branch
          setAssets(filteredAssets);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  useEffect(() => {
    let ignore = false;

    if (!assetsLoading) {
      return undefined;
    }

    getAssets(ignore);

    return () => {
      ignore = true;
    };

  }, [assetsLoading]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const trimmedremarks = trimWhitespaces(disposalRequests.remarks)
    
    console.log(disposalRequests);
    if (disposalRequests.assets.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset for Disposal is required.');
      return false;
    }

    if (disposalRequests.asset_image.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please upload atleast 1 image.')
      return
    }
    
    if (disposalRequests.store_contact_name.length === 0) {

      setOpenMessageDialog(true);
      setMessage('Store contact name is required')
      return

    }

    if (disposalRequests.store_contact_no.length === 0) {

      setOpenMessageDialog(true);
      setMessage('Store contact no is required')
      return
      
    }
    

    if (!isValidSpecialCharacter(trimmedremarks) && trimmedremarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in remarks.')
      return
    }

    try {
      const formData = new FormData();
      if (disposalRequests.asset_image.length >  0) {
        disposalRequests.asset_image.forEach((file, index) => {
          formData.append(`asset_image_${index}`, file);
        });
      }

      // Add other fields to formData if needed
      formData.append('assets', disposalRequests.assets);
      formData.append('remarks', disposalRequests.remarks);
      formData.append('branch_contact_person', disposalRequests.store_contact_name);
      formData.append('branch_contact_number', disposalRequests.store_contact_no);
      formData.append('signature_url', disposalRequests.signature_url);

      const options = {
          method: 'POST',
            url: '/AssetsDisposal/addRequest.json',
            data: formData,
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEdit((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {

    setDisposalRequests({

      assetSelected: [],
      assets: [],
      asset_image: [],
      remarks: '',
      store_contact_name: '',
      store_contact_no: '',
      signature_url: ''

    });

  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New Disposal request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="combo-box-demo"
                open={openAssets}
                onOpen={() => {setOpenAssets(true);}}
                onClose={() => {setOpenAssets(false);}}
                loading={assetsLoading}
                onChange={handleAssetsChange}
                options={assets.sort((a, b) => -b.warehouse_branch.localeCompare(a.warehouse_branch))}
                value={assets.label}
                noOptionsText={'No Available Data'}
                groupBy={(option) => option.warehouse_branch}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Asset for Disposal*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {assetsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} mb={2}>
              <Typography mb={1}>Scrap asset picture:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {disposalRequests.asset_image.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {disposalRequests.asset_image.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input Remarks" 
                  onChange={(e) => setDisposalRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Store Contact Person*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setDisposalRequests((prevAccount) => ({ ...prevAccount, store_contact_name: e.target.value }))}
                    value={disposalRequests.store_contact_name}
                />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="name"
                label="Store contact no.*"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  const input = e.target.value;
                  // Regular expression to allow up to 11 digits
                  if (/^\d{0,11}$/.test(input)) {
                    setDisposalRequests((prevData) => ({ ...prevData, store_contact_no: input }));
                  }
                }}
                value={disposalRequests.store_contact_no}
              />
            </Grid>

            <Grid item xs={12}>
              <Button 
                variant='contained' 
                color='primary' 
                style={{ marginBottom: '8px' }} 
                onClick={handleOpenSignature}>
                Signature
              </Button>
              <Dialog open={openSignature} onClose={() => setOpenSignature(false)}>
                <DialogTitle>Add signature</DialogTitle>
                <DialogContent>
                <ReactSignatureCanvas 
                  penColor='black'
                  canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
                  ref={signatureRef}
                />
                </DialogContent>
                <DialogActions>
                  <Button 
                      onClick={handleSaveSignature} 
                      variant='contained'
                      sx={{
                            color: theme.palette.primary.main,
                            bgcolor: '#7acda1',
                            '&:hover': {
                              bgcolor: '#ACE1C3',
                            },
                          }}  
                      >Done</Button>
                  <Button onClick={handleClearSignature} color="primary">clear</Button>
                  <Button onClick={() => setOpenSignature(false)} color="error">cancel</Button>
                </DialogActions>
              </Dialog>
            </Grid>

        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddDisposalRequest