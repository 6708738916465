import { Close, CloudUpload, DeleteSweep } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import ReactSignatureCanvas from 'react-signature-canvas';
import { useTheme } from '@emotion/react';
import useAssetDisposalContext from '../../hooks/UseAssetDisposalContext';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function DisposeScrapAsset({disposalDetails, setonRejectOrApprove}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useAssetDisposalContext();
  const [open, setOpen] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const signatureRef = useRef();
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    image_receipt: [],
    signature_url: '',
    assets: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id, assets} = disposalDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code || prevData.request_code, 
      id: id || prevData.id,
      assets: assets,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (requests.image_receipt.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please upload atleast 1 image.')
      return
    }
    try{
      setLoading(true);
      setShowProgress(true);

      const formData = new FormData();
        if (requests.image_receipt.length >  0) {
          requests.image_receipt.forEach((file, index) => {
            formData.append(`image_receipt_${index}`, file);
          });
        }
        formData.append('request_code', requests.request_code);
        formData.append('id', requests.id);
        formData.append('signature_url', requests.signature_url);
        formData.append('assets', requests.assets);
      const options = {
          'method': 'POST',
          'url' : '/AssetsDisposal/disposeScrapAsset.json',
          'data': formData
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate(prev => !prev)
          setOpen(false)
          setShowProgress(false);
          setonRejectOrApprove((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRequests((prevAccount) => ({ ...prevAccount, image_receipt: [...prevAccount.image_receipt, ...files]}))
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = requests.image_receipt.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRequests(prevState => ({
      ...prevState,
      image_receipt: updatedImages
    }));
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleOpenSignature = () => {
    setOpenSignatureDialog(true)
  }

  const handleSaveSignature = () => {
    const dataURL = signatureRef.current.toDataURL('image/png');
    if (dataURL) {
      setRequests((prevData) => ({...prevData, signature_url: dataURL}));
      setOpenSignatureDialog(false)
    }
  };

  const handleClearSignature = () => {
    signatureRef.current.clear();
  };


  return (
    <>
      <Button 
        disabled={disposalDetails.status === 2 || disposalDetails.status === 0 ? true : false} 
        variant='contained' 
        size="small" 
        color='error' 
        startIcon={<DeleteSweep />} 
        onClick={handleOpen}>
        Dispose scrap asset
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'md'}
        >
      <DialogTitle>Dispose scrap asset</DialogTitle>
      <DialogContent>
        <Grid container direction='column' spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>Upload receipt:</Typography>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
              >
              Upload images
              <VisuallyHiddenInput
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>
            {requests.image_receipt.length > 0 && (
              <>
                <Typography mt={2}>Selected Image:</Typography>
                <ul>
                  {requests.image_receipt.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                        <Close />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
          <Button 
            variant='contained' 
            size="small" 
            color='primary' 
            style={{ marginRight: '8px' }} 
            onClick={handleOpenSignature}>
            signature
          </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <Dialog open={openSignatureDialog} onClose={() => setOpenSignatureDialog(false)}>
      <DialogTitle>Add signature</DialogTitle>
      <DialogContent>
      <ReactSignatureCanvas 
        penColor='black'
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
        ref={signatureRef}
      />
      </DialogContent>
      <DialogActions>
        <Button 
            onClick={handleSaveSignature} 
            variant='contained'
            sx={{
                  color: theme.palette.primary.main,
                  bgcolor: '#7acda1',
                  '&:hover': {
                    bgcolor: '#ACE1C3',
                  },
                }}  
            >Save</Button>
        <Button onClick={handleClearSignature} color="primary">clear</Button>
        <Button onClick={() => setOpenSignatureDialog(false)} color="error">cancel</Button>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default DisposeScrapAsset