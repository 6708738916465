import { useEffect, useState } from 'react';
import { AxiosCustomConfig } from '../config/AxiosCustomConfig';


const useFetchPageLength = (functionName, setOpenMessageDialog, setMessage, onSaveColumns) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [pageSizeOptions, setPageSizeOptions] = useState([5, 10, 25, 50, 100]);
  const axios = AxiosCustomConfig();
  useEffect(() => {
    const getPageLength = async () => {
      try {
        const options = {
          method: 'POST',
          url: '/GeneralSettings/getPageLengthSettings.json',
          data: { function_name: functionName }
        };
        const response = await axios(options);
        const { pageLength } = response.data;

        setPaginationModel(prev => ({ ...prev, pageSize: pageLength }));

        setPageSizeOptions(prevOptions => {
          if (!prevOptions.includes(pageLength)) {
            const newOptions = [...prevOptions, pageLength].sort((a, b) => a - b);
            return newOptions;
          }
          return prevOptions;
        });
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Network error.`);
      }
    };

    getPageLength();
  }, [functionName, onSaveColumns]);

  return { paginationModel, setPaginationModel, pageSizeOptions };
};

export default useFetchPageLength;
