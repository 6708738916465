
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs, } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import { TransferAssetRequestProvider } from '../../context/TransferAssetRequestProvider';
import TransferAssetLists from './TransferAssetLists';
import EditTransferAssetRequest from './EditTransferAssetRequest';
import ApproveTransferRequest from './ApproveTransferRequest';
import DeployTransferAssetRequest from './DeployTransferAssetRequest';
import ConfirmDeployAssets from './ConfirmDeployAssets';
import MoreOptionBtn from './MoreOptionBtn';
import OtherCharges from './OtherCharges';
import TadrPrint from '../Utils/TadrPrint';


const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);
const TransferAssetsWarehouseDetails = () => {
  const { requestCode } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [transferRequests, setTransferRequests] = useState({});
  // const [assetCode, setAssetCode] = useState([]);
  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/TransferAssetRequests/getAllTransferRequestsDetails.json',
          'data': {'request_code': requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      setTransferRequests(data);
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [doneUpdateRequest]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  return (
    <TransferAssetRequestProvider>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/transferAssets"
          >
            Transfer Assets
          </Link>
          <Typography color="text.primary">Details</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                  <Paper elevation={1} style={{ height: '100%', padding: '8px', display: 'flex', gap: '8px' }}>
                    {(Number(auth.role) === 6 || Number(auth.role) === 0)  && (
                      <>
                        <EditTransferAssetRequest transferRequests={transferRequests} setDoneUpdateRequest={setDoneUpdateRequest} />
                        <DeployTransferAssetRequest transferRequests={transferRequests} setDoneUpdateRequest={setDoneUpdateRequest} />
                        <ConfirmDeployAssets transferRequests={transferRequests} setDoneUpdateRequest={setDoneUpdateRequest} />
                      </>
                    )}

                    {Number(auth.role) !== 6 && (
                      <>
                        <ApproveTransferRequest transferRequests={transferRequests} setDoneUpdateRequest={setDoneUpdateRequest}/>
                      </>
                    )}

                    {Number(auth.role) === 6 || Number(auth.role) === 2 || Number(auth.role) === 0 || Number(auth.role) === 3 ? (
                      <>
                        <TadrPrint requestDetails={transferRequests} />
                        <MoreOptionBtn transferRequests={transferRequests} setDoneUpdateRequest={setDoneUpdateRequest}/>
                      </>
                    ) : ''} 
                    
                  </Paper>
              </Grid>
              
              <Grid item>
                <Paper elevation={1} style={{ height: '100%' }}>
                  <TransferAssetLists requestCode={requestCode}/>
                </Paper>
              </Grid>

              <Grid item>
                <Paper elevation={1} style={{ height: '100%' }}>
                    <OtherCharges requestCode={requestCode} setDoneUpdateRequest={setDoneUpdateRequest}/>
                </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Info</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(transferRequests)
                    .filter(([key, _]) =>  key !== 'assets' && key !== 'signature' && key !== 'origin_warehouse_unique_code' && key !== 'destination_warehouse_unique_code' && key !== 'destination_warehouse_unique_code' && key !== 'destination_username' && key !== 'origin_username' && key !== 'hauling_rate_unique_code' && key !== 'blank_tadr_display' && key !== 'signature_image_convert' && key !== 'signature_requestor_image_convert')
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell style={key === 'status' ? { backgroundColor: value === '0' ? 'blue' : value === '1' ? 'orange' : 'inherit' } : {}}>
                        {key === 'status' ? (
                          value === 0 ? <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} /> : 
                          value === 1 ? <Chip label='Delivery in progress' sx={{ backgroundColor: '#D7D6D4'}} />: 
                          value === 2 ? <Chip label='Completed' sx={{ backgroundColor: '#3AD658' }} />: 
                          value === 3 ? <Chip label='Delivered' color='success'  />: 
                          value === 4 ? <Chip label='Transfer form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />:
                          value === 5 ? <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />: 
                          value === 6 ? <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />: 
                          value === 7 ? <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />:
                            ''
                        ) : key === 'date_added' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'date_approve' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        )  : key === 'date_served' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'completion_date' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : key === 'approver_signature' ? (
                            value !== '' ? <img
                              src={value}
                              alt='after repair'
                              style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                            /> : ''
                        ) : key === 'signature_of_requestor' ? (
                          value ? <img
                            src={value}
                            alt='after repair'
                            style={{ width: 200, height: 100, objectFit: 'contain' , border: '1px solid black'}}
                          /> : ''
                        ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </TransferAssetRequestProvider>
  )
}

export default TransferAssetsWarehouseDetails