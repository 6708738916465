import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, ImageList, CircularProgress, InputLabel, NativeSelect, Paper, StyledEngineProvider, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function EditAssets({assetsData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    code: '',
    name: '',
    description: '',
    id: '',
    image: '',
    warehouse: [],
    asset_type: [],
    asset_models_array: [],
    asset_status_array: [],
    warehouse_unique_code: '',
    asset_type_code: '',
    asset_jde_no: '',
    asset_model: '',
    asset_status: '',
    warehouse_name: '',
    store_name: '',
    account_code: '',
    store_array: '',
  });

  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [openAssetTypes, setOpenAssetTypes] = useState(false);
  const [loadingAssetType, setLoadingAssetType] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouses, setOpenWarehouses] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [assetModels, setAssetModels] = useState([]);
  const [openAssetModels, setOpenAssetModels] = useState(false);
  const [loadingAssetModels, setLoadingAssetModels] = useState(false);
  const [assetStatus, setAssetStatus] = useState([]);
  const [openAssetStatus, setOpenAssetStatus] = useState(false);
  const [loadingAssetStatus, setLoadingAssetStatus] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [openStoreData, setOpenStoreData] = useState(false);
  const [loadingStoreData, setLoadingStoreData] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    const { code, name, description, id, warehouse_name, warehouse_unique_code, asset_type_name, asset_type_code, asset_jde_no, asset_model, asset_status, acquisition_date, acquisition_value, net_book_value, serial_no, jde_system_status, store_name, jde_syspro_customer_no, account_unique_code, asset_type_size} = assetsData;
    setEditData((prevData) => ({ 
      ...prevData, 
      code: code || assetsData.equipment_no,  
      name: name || assetsData.name,  
      description: description || assetsData.description,
      id: id || assetsData.id,
      warehouse: {'label' : warehouse_name || assetsData.warehouse_branch, value: warehouse_unique_code || assetsData.warehouse_unique_code},
      asset_type: {'label' : asset_type_size || assetsData.asset_type_size, value: asset_type_size || assetsData.asset_type_size},
      warehouse_unique_code: warehouse_unique_code || assetsData.warehouse_unique_code,
      warehouse_name: warehouse_name || assetsData.warehouse_branch,
      asset_type_code: asset_type_code || assetsData.asset_type_code,
      asset_jde_no: asset_jde_no || assetsData.asset_jde_no,
      asset_model: asset_model || assetsData.asset_model,
      asset_models_array: {'label' : asset_model || assetsData.asset_model, value: asset_model || assetsData.asset_model},
      asset_status: asset_status || assetsData.asset_status,
      acquisition_date: acquisition_date || assetsData.acquisition_date,
      acquisition_value: acquisition_value || assetsData.acquisition_value,
      net_book_value: net_book_value || assetsData.net_book_value,
      serial_no: serial_no || assetsData.serial_no,
      system_status: jde_system_status || assetsData.asset_status,
      asset_status_array: {'label' : asset_status || assetsData.asset_status, value: asset_status || assetsData.asset_status},
      store_array: {'label' : store_name || assetsData.store_name, value: store_name || assetsData.store_name},
      store_name : store_name || assetsData.store_name,
      account_code : jde_syspro_customer_no || assetsData.jde_syspro_customer_no,
      account_unique_code : account_unique_code || assetsData.account_unique_code,
      asset_type_size : asset_type_size || assetsData.asset_type_size,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      code: '',
      name: '',
      description: '',
      id: '',
      image: '',
      warehouse: [],
      asset_type: [],
      asset_models_array: [],
      asset_status_array: [],
      warehouse_unique_code: '',
      asset_type_code: '',
      asset_jde_no: '',
      asset_model: '',
      asset_status: '',
      asset_status_unique_code: '',
      warehouse_name: '',
      store_name: '',
      account_code: '',
      store_array: '',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editData.name || !editData.code) {
        setOpenMessageDialog(true);
        setMessage('Asset Name and Equipment no. are required')
        return
    }

    if (!isValidSpecialCharacter(editData.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Asset Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.code)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Equipment no.')
        return
    }

    // if (!isValidSpecialCharacter(editData.description) && editData.description !== '') {
    //   setOpenMessageDialog(true);
    //   setMessage('Invalid Special Characters found in Description.')
    //   return
    // }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/updateAsset.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        const { is_success, message} = response.data

        setShowProgress(false);
        if (is_success) {
          setOpenMessageDialog(true);
          setMessage(message)
          resetForm();
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          if (message) {
            setMessage(message)
          } else {
            setMessage('Failed to Save.')
          }
          
        }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  const handleAssetTypeChange = (e, selectValue) => {
      if (selectValue) {
        let asset_type_code = selectValue.asset_type_code;
        setEditData((prevAccount) => ({
          ...prevAccount, 
          asset_type_code: asset_type_code, 
          asset_type: selectValue
        }))
      }
  }

  const handleAssetModelsChange = (e, selectValue) => {
      if (selectValue) {
        let asset_model = selectValue.asset_model;
        setEditData((prevAccount) => ({
          ...prevAccount, 
          asset_model: asset_model, 
          asset_models_array: selectValue
        }))
      }
  }

  const handleAssetStatusChange = (e, selectValue) => {
      if (selectValue) {
        let asset_status = selectValue.asset_status;
        let asset_status_unique_code = selectValue.asset_status_unique_code;
        setEditData((prevAccount) => ({
          ...prevAccount, 
          asset_status: asset_status, 
          asset_status_unique_code:asset_status_unique_code, 
          asset_status_array: selectValue
        }))
      }
  }

  const getAssetTypeSize = async (ignore) => {
    try {
        setLoadingAssetType(true)
        const options = {
            method: 'POST',
            url: '/AssetSizes/getAssetSizesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetTypes(response.data);
          setLoadingAssetType(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setEditData((prev) => ({...prev, image: file}));
  }

  const getAssetModels = async (ignore) => {
    try {
        setLoadingAssetModels(true)
        const options = {
            method: 'POST',
            url: '/AssetModels/getAssetModelsNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetModels(response.data);
          setLoadingAssetModels(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetStatus = async (ignore) => {
    try {
        setLoadingAssetStatus(true)
        const options = {
            method: 'POST',
            url: '/AssetStatus/getAssetStatusNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetStatus(response.data);
          setLoadingAssetStatus(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssetTypes) return undefined
    getAssetTypeSize(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetTypes]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetModels) return undefined
    getAssetModels(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetModels]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetStatus) return undefined
    getAssetStatus(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetStatus]);

  const getWarehouses = async (ignore) => {
    try {
        setLoadingWarehouses(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }

        const response = await axios(options);
        if(!ignore){
          setWarehouses(response.data);
          setLoadingWarehouses(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getStoreData = async (ignore) => {
    try {
        setLoadingStoreData(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if(!ignore){
          setStoreData(response.data);
          setLoadingStoreData(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openStoreData) return undefined
    getStoreData(ignore);

    return () => {
      ignore = true;
    }
  }, [openStoreData]);

  useEffect(()=>{
    let ignore = false;

    if (!openWarehouses) return undefined
    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouses]);

  const handleWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      let warehouse_unique_code = selectValue.warehouse_unique_code;
      let warehouse_name = selectValue.warehouse_name;
      setEditData((prevAccount) => ({ ...prevAccount, warehouse_unique_code: warehouse_unique_code, warehouse_name: warehouse_name, warehouse: selectValue}))
    }
  }

  const handleSelectSystemStatusChange = (event, newValue) => {
    setEditData((prevAccount) => ({ ...prevAccount, system_status: event.target.value }))
  };

  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, store_array:selectValue , store_name: selectValue.account_name, account_code: selectValue.account_code, account_unique_code: selectValue.account_unique_code }))
    }
  }
  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Account</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" alignItems="center" item xs={12} sx={{marginBottom:5}}>
                <Paper
                    sx={{
                        width: 300
                    }}
                >
                    <img
                        src={assetsData.image}
                        alt='Asset img'
                        loading="lazy"
                        style={{ width: "100%" }}
                    />
                </Paper>
          </Grid>

          <Grid container spacing={3}>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                    Upload New Image
                    <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange}/>
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {editData.image && (
                  <p>{editData.image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  label="Equipment no.*"
                  fullWidth
                  variant="standard"
                  value={editData.code}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow up to 11 digits
                    if (/^\d{0,64}$/.test(input)) {
                      setEditData((prevData) => ({ ...prevData, code: input }));
                    }
                  }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  label="Asset Name*"
                  fullWidth
                  variant="standard"
                  value={editData.name}
                  onChange={(e) => setEditData((prev) => ({ ...prev, name: e.target.value }))}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  label="Asset JDE No"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prev) => ({ ...prev, asset_jde_no: e.target.value }))}
                  value={editData.asset_jde_no}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetModels}
                  onOpen={() => {setOpenAssetModels(true);}}
                  onClose={() => {setOpenAssetModels(false);}}
                  loading={loadingAssetModels}
                  onChange={handleAssetModelsChange}
                  options={assetModels}
                  value={editData.asset_models_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Asset Model" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetStatus}
                  onOpen={() => {setOpenAssetStatus(true);}}
                  onClose={() => {setOpenAssetStatus(false);}}
                  loading={loadingAssetStatus}
                  onChange={handleAssetStatusChange}
                  options={assetStatus}
                  value={editData.asset_status_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Asset Status" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openStoreData}
                  onOpen={() => {setOpenStoreData(true);}}
                  onClose={() => {setOpenStoreData(false);}}
                  loading={loadingStoreData}
                  onChange={handleStoreAccontsChange}
                  options={storeData}
                  value={editData.store_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store/Business name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingStoreData ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Acquisition Date"
                            value={editData.acquisition_date ? dayjs(editData.acquisition_date) : null}
                            onChange={(newValue) => setEditData({
                              ...editData,
                              acquisition_date: newValue ? dayjs(newValue).format('YYYY-MM-DD') : null
                            })}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="acquisition_value"
                  label="Acquisition Value"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, acquisition_value: e.target.value }))}
                  value={editData.acquisition_value}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="net_book_value"
                  label="Net Book Value"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, net_book_value: e.target.value }))}
                  value={editData.net_book_value}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="serial_no"
                  label="Serial No"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, serial_no: e.target.value }))}
                  value={editData.serial_no}
              />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth sx={{marginTop: 2}}>
                  <InputLabel variant="standard">
                  JDE System status
                  </InputLabel>
                  <NativeSelect
                      defaultValue={editData.system_status}
                      inputProps={{
                          name: 'system_status',
                          id: 'system_status',
                      }}
                      onChange={handleSelectSystemStatusChange}
                  >
                    <option value={0}>Active</option>
                    <option value={3}>For write-off</option>
                    <option value={2}>Deleted</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            <Grid item xs={12} mb={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openWarehouses}
                  onOpen={() => {setOpenWarehouses(true);}}
                  onClose={() => {setOpenWarehouses(false);}}
                  loading={loadingWarehouses}
                  onChange={handleWarehouseChange}
                  options={warehouses}
                  value={editData.warehouse}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => {
                      return option.value === value.value;
                    }}
                  renderInput={(params) => <TextField {...params} label="Warehouse Branch" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetTypes}
                  onOpen={() => {setOpenAssetTypes(true);}}
                  onClose={() => {setOpenAssetTypes(false);}}
                  loading={loadingAssetType}
                  onChange={handleAssetTypeChange}
                  options={assetTypes}
                  value={editData.asset_type}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label="Asset Type Size" />}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={editData.description}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => { 
              setOnEditOrDelete((prev) => !prev)
              setOpenMessageDialog(false)
            }}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditAssets