import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddDeploymentType({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [deploymentTypes, setDeploymentTypes] = useState({
    name: '',
    description: '',
  });

  const handleOpen = (e) => {
    setOpen(true)
    e.stopPropagation();
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!deploymentTypes.name) {
        setOpenMessageDialog(true);
        setMessage('Asset Type Name is required')
        return
    }

    if (!isValidSpecialCharacter(deploymentTypes.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(deploymentTypes.description)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/DeploymentTypes/addTypes.json',
              data: objectToFormData(deploymentTypes),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setDeploymentTypes({
      name: '',
      description: '',
    });
  };


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Deployment Type</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Type</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="account_name"
                  label="Name"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setDeploymentTypes((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={deploymentTypes.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setDeploymentTypes((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={deploymentTypes.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddDeploymentType