import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import { CloudUpload } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function AddOtherCharges({requestCode, setDoneUpdateRequest, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [otherCharges, setOtherCharges] = useState({
    request_code: '',
    charge_name: '',
    charge_price: '',
    attachment: ''
  });

  const handleOpen = () => {
    setOpen(true)
    setOtherCharges((prev) => ({...prev, request_code: requestCode}))

  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!otherCharges.charge_name) {
      setOpenMessageDialog(true);
      setMessage('Charge name is required.');
      return false;
    }
    if (!otherCharges.charge_price) {
      setOpenMessageDialog(true);
      setMessage('Amount is required.');
      return false;
    }

    if (!otherCharges.attachment) {
      setOpenMessageDialog(true);
      setMessage('Please upload attachment')
      return
    }

    try {

      const options = {
          method: 'POST',
            url: '/OtherCharges/addCharges.json',
            data: objectToFormData(otherCharges),
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        setDoneUpdateRequest(prev => !prev)
        setOnEditOrDelete(prev => !prev)
        resetForm();
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setOtherCharges({
      request_code : '',
      charge_name: '',
      charge_price: '',
      attachment: ''
    });
  };

  const handleaAttachmentChange = (event) => {
    const file = event.target.files[0];
    setOtherCharges((prev) => ({...prev, attachment: file}));
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>Add</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add Other charges</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
                <TextField
                    name="name"
                    label="Charge name"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setOtherCharges((prevAccount) => ({ ...prevAccount, charge_name: e.target.value }))}
                    value={otherCharges.charge_name}
                />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
                <TextField
                    name="name"
                    label="Amount"
                    fullWidth
                    variant="standard"
                    onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 10000000) {
                            setOtherCharges((prevAccount) => ({ ...prevAccount, charge_price: input }));
                          }
                      }
                  }}
                    value={otherCharges.charge_price}
                />
            </FormControl>
          </Grid>
          <Grid container item xs={12} alignItems="center" spacing={2} >
                  <Grid item>
                    <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                      Upload Attachment
                      <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleaAttachmentChange}/>
                    </Button>
                  </Grid>
                  <Grid item>
                    {otherCharges.attachment && (
                      <p>{otherCharges.attachment.name}</p>
                    )}
                  </Grid>
                </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddOtherCharges