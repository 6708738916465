import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, StyledEngineProvider, Typography } from '@mui/material';
import React, { useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Edit, HighlightOff } from '@mui/icons-material';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function UpdateRemarks({repairDetails, setonRejectOrApprove}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [remarks, setRemarks] = useState({remarks: '', id:''});
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog, setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true)
    const {id, remarks} = repairDetails;
    setRemarks({id, remarks});
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleReject = async (e) => {
    e.stopPropagation();
    if(!remarks.remarks) {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage('PLease input reason for rejecting the request.')
      return
    }

    setShowProgress(true)
    try {
      const options = {
        method: 'POST',
        url: '/ShopRepairs/updateRemarks.json',
        data: objectToFormData(remarks),
      }

      const response = await axios(options);

      setShowProgress(false)

      if (response.data.is_success) {
        setOpenMessageDialog(true);
        setMessage(response.data.message)
        setOpen(false)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true)
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  return (
    <>
      <Button 
        disabled={repairDetails.status === 4 || repairDetails.status === 5 || repairDetails.status === 3 ? true : false} 
        variant='contained' 
        size="small" 
        color='info' 
        startIcon={<Edit />} 
        onClick={handleOpen}
        >
        Update remarks
      </Button> 
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
      <DialogTitle>Remarks details</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align='center'>
            Are you sure you want to update remarks? <p> <strong> {repairDetails.request_code}</strong>  </p>
          </DialogContentText>

          <Typography>Remarks:</Typography>
          <Textarea 
            aria-label="minimum height" 
            minRows={3} 
            placeholder="Input Remarks" 
            onChange={(e) => setRemarks((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
          />
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, paddingTop: 3}}>
            <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' autoFocus onClick={handleReject}>Save</Button>
            </FormControl>
            <FormControl fullWidth>
              <Button variant="outlined" size="small" onClick={handleClose}>Cancel</Button>
            </FormControl>
            
        </DialogActions>
        </DialogContent>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            setonRejectOrApprove((prev) => !prev)
            } }
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default UpdateRemarks