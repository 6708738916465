import { Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, NativeSelect, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import styled from '@emotion/styled';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function ChangeStatus({open, setOpen, requestDetails, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetStatus, setAssetStatus] = useState([]);
  const [assetStatusLoading, setAssetStatusLoading] = useState(false);
  const [openAssetStatus, setopenAssetStatus] = useState(false);
  const [deploymentTypesData, setDeploymentTypesData] = useState({
    request_code: '',
    store_account: [],
    account_code: '', 
    asset_status_array: [],
    dep_req_status_arr: [],
    status_name: '',
    status_unique_code: '',
    remarks: '',
    asset_code: '',
    distributor_company_id: '',
    account_unique_code: '',
  });

  const [stores, setStores] = useState([]);
  const [loadingStore, setLoadingStore] = useState(false);
  const [openStore, setopenStore] = useState(false);

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!deploymentTypesData.account_code) {
        setOpenMessageDialog(true);
        setMessage('Store is required')
        return
    }

    if (!deploymentTypesData.remarks || deploymentTypesData.remarks === '') {
      setOpenMessageDialog(true);
      setMessage('Remarks is required')
      return
    }
    if (!deploymentTypesData.dep_req_status_arr.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset status is required')
      return
    }



    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/DeploymentRequests/changeStatus.json',
              data: objectToFormData(deploymentTypesData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setOnEditOrDelete((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setDeploymentTypesData({
      request_code: '',
      store_account: [],
      account_code: '', 
      asset_status_array: [],
      dep_req_status_arr: [],
      status_name: '',
      status_unique_code: '',
      remarks: '',
      asset_code: '',
      distributor_company_id: '',
      account_unique_code: '',
    });
  };

  const getAssetStatus = async (ignore) => {
    try {
      setAssetStatusLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetStatus/getAssetStatusNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetStatus(response.data);
        }
        
      setAssetStatusLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openAssetStatus) {
      setAssetStatus([]);
    }

    if (!openAssetStatus) return undefined
    getAssetStatus(ignore)

    return () => {
      ignore = true
    };
  }, [openAssetStatus]);

  const deploymentRequestStatus = [
    { label: 'For approval', id: 0 },
    { label: 'Processing', id: 1 },
    { label: 'Deployed', id: 2 },
    { label: 'Approved', id: 3 },
    { label: 'Rejected', id: 4 },
    { label: 'Cancelled', id: 5 },
    { label: 'Unproductive', id: 6 },
    { label: 'Unproductive & cancelled', id: 7 },
    { label: 'Hold', id: 8 },
  ];


  useEffect(() => {
    if (open && requestDetails) {
      const { request_code, account_code, account_name, remarks, asset_status_name, asset_status_unique_code, asset_code, status_code, status_name, distributor_company_id, account_unique_code } = requestDetails;

      setDeploymentTypesData({
        request_code: request_code,
        store_account: { 'label': `${account_code} - (${account_name})`, value: account_code },
        account_code: account_code, 
        asset_status_array: { 'label': asset_status_name, value: asset_status_name },
        dep_req_status_arr: { 'label': status_name, id: status_code },
        status_name: asset_status_name,
        status_unique_code: asset_status_unique_code,
        remarks: remarks,
        asset_code: asset_code,
        request_status_code: status_code,
        request_status_name: status_name,
        distributor_company_id: distributor_company_id,
        account_unique_code: account_unique_code,
      });

    }

  }, [open, requestDetails]);

  
  
  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setDeploymentTypesData((prevData) => ({ ...prevData, store_account: selectValue, account_code: selectValue.account_code, distributor_company_id: selectValue.distributor_company_id}))
    }
  }

  const getStores = async (ignore) => {
    try {
      setLoadingStore(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);
        }
        
        setLoadingStore(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openStore) {
      setStores([]);
    }

    if (!openStore) return undefined
    getStores(ignore)

    return () => {
      ignore = true
    };
  }, [openStore]);

  const handleAssetStatusChange = (e, selectValue) => {
    if (selectValue) {
      let asset_status = selectValue.asset_status;
      let asset_status_unique_code = selectValue.asset_status_unique_code;
      setDeploymentTypesData((prevAccount) => ({
        ...prevAccount, 
        status_name: asset_status, 
        status_unique_code: asset_status_unique_code, 
        asset_status_array: selectValue
      }))
    }
  }

  const handleStatusChange = (event, newValue) => {
    if (newValue) {
      const { id, label } = newValue;

      // Update the selected status
      setDeploymentTypesData((prevAccount) => ({
        ...prevAccount,
        dep_req_status_arr: newValue,
        request_status_code: id, 
        request_status_name: label,
      }))
    }
  };

  return (
    <div>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Change Status</DialogTitle>
      <DialogContent>
        <Box sx={{marginBottom: 2 }}>
            <Chip label="Assets" size="small" color="secondary" />
        </Box>
          <Grid container spacing={3} mb={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openAssetStatus}
                  onOpen={() => {setopenAssetStatus(true);}}
                  onClose={() => {setopenAssetStatus(false);}}
                  loading={assetStatusLoading}
                  onChange={handleAssetStatusChange}
                  options={assetStatus}
                  value={requestDetails && deploymentTypesData.asset_status_array}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asset status*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {assetStatusLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{marginBottom: 2, marginTop: 2  }}>
            <Chip label="Deployment Request" size="small" color="secondary" />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl FormControl fullWidth>
                <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={handleStoreAccontsChange}
                open={openStore}
                onOpen={() => {setopenStore(true);}}
                onClose={() => {setopenStore(false);}}
                options={stores}
                loading={loadingStore}
                value={deploymentTypesData.store_account}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Store*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingStore ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                />
            </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography>*Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Remarks" 
                  onChange={(e) => setDeploymentTypesData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                  value={deploymentTypesData.remarks}
                />
              </FormControl>
              <Typography variant="body2" color="initial">Pls provide a reason for unproductive delivery</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <Autocomplete
                  disablePortal
                  options={deploymentRequestStatus}
                  value={deploymentTypesData.dep_req_status_arr}
                  onChange={handleStatusChange}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label="Request Status" />}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default ChangeStatus