import { Avatar, Box, Chip, StyledEngineProvider } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import useSortModel from '../../hooks/UseSortModel';
import ViewAssetBtn from '../AssetRegistry/ViewAssetBtn';
import AddAssets from '../AssetRegistry/AddAssets';
import useAuth from '../../hooks/UseAuth';
import EditAssets from '../AssetRegistry/EditAssets';
import DeleteAssets from '../AssetRegistry/DeleteAssets';

const Assets = ({uniqueCode}) => {
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'assets';
  const modelTable = 'Assets';
  const [rowCount, setRowCount] = useState(0);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);


  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });
  const [pageSizeOptions, setPageSizeOptions] = useState([5, 10, 25, 50, 100]);

  const [sortModel, setSortModel] = useSortModel([]);

  useEffect(() => {
    let isMounted = true;
    const getAssets = async (uniqueCode) => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getAssetsPerCustomer.json',
          'data': {
            account_unique_code: uniqueCode,
          }
        }
        const response = await axios(options);
        
        if (isMounted) {
          const { data, totalCount } = response.data;
          setAssets(data);
          setRowCount(totalCount);
        }
      } catch (error) {
        if (isMounted) {
          setOpenMessageDialog(true);
          setMessage( `${error.toJSON().message}. Network error.`);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    getAssets(uniqueCode);

    return () => {
      isMounted = false;
    };
  }, [onEditOrDelete, uniqueCode]);

  const columns = [
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      wrap: true,
      renderCell: (params) => (
        (Number(auth.role) === 0 || Number(auth.role) === 2) ?
        <div style={{ display: 'flex', gap: '5px' }}>
          <EditAssets assetsData={params.row} setOnEditOrDelete={setOnEditOrDelete} />
          <DeleteAssets assetsData={params.row} setOnEditOrDelete={setOnEditOrDelete} />
          <ViewAssetBtn assetsData={params.row} />
        </div> : <div style={{ display: 'flex', gap: '5px' }}></div>
      ),
    },
    { field: 'created', headerName: 'Date Added',flex: 1, minWidth: 150, wrap: true },
    { field: 'code', headerName: 'Equipment No.', flex: 1, minWidth: 150, wrap: true,},
    { field: 'name', headerName: 'Asset name',flex: 2, minWidth: 150, wrap: true,  },
    { field: 'jde_syspro_customer_no', headerName: 'Jde Syspro Customer No',flex: 1, minWidth: 150,},
    { field: 'asset_status', headerName: 'Status',flex: 1, minWidth: 150,},
    { field: 'asset_model', headerName: 'Asset Model',flex: 1, minWidth: 150,},
    { field: 'asset_jde_no', headerName: 'Asset Jde No',flex: 1, minWidth: 150,},
    { field: 'store_name', headerName: 'Store/Business name',flex: 1, minWidth: 300,},
    { field: 'asset_type_size', headerName: 'Asset Type Size',flex: 1, minWidth: 150,},
    { field: 'audit_comment', headerName: 'Audit Comment',flex: 1, minWidth: 150,},
    { field: 'warehouse_branch', headerName: 'Warehouse Branch',flex: 2, minWidth: 150,},
    { field: 'store_address', headerName: 'Store Address',flex: 1, minWidth: 150,},
    { field: 'audit_period', headerName: 'Audit Period',flex: 1, minWidth: 150,},
    { field: 'audit_remarks', headerName: 'Audit Remarks',flex: 1, minWidth: 150,},
    { field: 'channel', headerName: 'Channel',flex: 1, minWidth: 150,},
    { field: 'contact_no', headerName: 'Contact No',flex: 1, minWidth: 150,},
    { field: 'contact_person', headerName: 'Contact Person',flex: 1, minWidth: 150,},
    { field: 'created_by', headerName: 'Created By',flex: 1, minWidth: 150,},
    { field: 'customer_type', headerName: 'Customer Type',flex: 1, minWidth: 150,},
    { field: 'region', headerName: 'Region',flex: 1, minWidth: 150,},
    { field: 'remarks', headerName: 'Remarks',flex: 1, minWidth: 150,},
    {
      field: 'jde_system_status',
      headerName: 'JDE System Status',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const value = params.value;
    
        return value === 0 ? <Chip label="Active" color="success" /> : 
              value === 1 ? <Chip label="Deleted" color="error" /> : 
              value === 2 ? <Chip label="Inactive" color="warning" /> : 
              value === 3 ? <Chip label="For write-off" color="primary" /> : 
              null;
      },
    },
  ];
  

  return (
    <>
      <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Container for left-aligned buttons */}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <AddAssets uniqueCode={uniqueCode} setOnEditOrDelete={setOnEditOrDelete} />
        </Box>

        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Settings Icon and Menu */}
        {/* <Box sx={{ position: 'relative' }}>
          <ColumnSettingsButton
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            paginationModel={paginationModel}
          />
        </Box> */}
      </Box>
      <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
        <DataGrid
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
            footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>,
          }}
          pageSizeOptions={pageSizeOptions}
          rows={assets}
          columns={columns}
          disableRowSelectionOnClick
          pagination
          sortingOrder={['asc', 'desc']}
          loading={loading}
          getRowHeight={() => 'auto'}
          autoHeight
          keepNonExistentRowsSelected
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: 1,
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '10px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '10px',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              opacity: 0.5, 
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }
          }}
        />
      </Box>
      <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </>
  )
}

export default Assets