import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditCustomerChain({customerChainData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    chain_code: '',
    chain_name: '',
    customer_group: '',
    customer_type_array: [],
    customer_type: '',
    active: '',
    id: ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [customerTypes, setcustomerTypes] = useState([]);
  const [loadingCustomerTypes, setLoadingustomerTypes] = useState(false);
  const [openCustomerTypes, setOpenustomerTypes] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { chain_code, chain_name, customer_group, customer_type, is_active, id} = customerChainData;
    
    let activeWord = is_active === 1 ? 'Yes' : 'No'
    setEditData((prevData) => ({ 
      ...prevData, 
      chain_code: chain_code || prevData.chain_code, 
      chain_name: chain_name || prevData.chain_name, 
      customer_group: customer_group || prevData.customer_group, 
      customer_type_array: { label : customer_type, customer_type : customer_type}, 
      customer_type: customer_type || prevData.customer_type, 
      active: activeWord || prevData.activeWord, 
      id: id || prevData.id,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      chain_code: '',
      chain_name: '',
      customer_group: '',
      customer_type_array: [],
      customer_type: '',
      active: '',
      id: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const allowedActiveInput = ['Yes', 'No'];
    if (!editData.chain_code) {
        setOpenMessageDialog(true);
        setMessage('Customer chain code is required')
        return
    }

    if (!editData.chain_name) {
      setOpenMessageDialog(true);
      setMessage('Customer chain name is required')
      return
  }

    if (!editData.customer_type) {
        setOpenMessageDialog(true);
        setMessage('Customer type is required')
        return
    }
    if (!editData.active) {
        setOpenMessageDialog(true);
        setMessage('Active is required')
        return
    }

    if (!isValidSpecialCharacter(editData.chain_code)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Customer chain code.')
        return
    }

    if (!isValidSpecialCharacter(editData.chain_name) && editData.chain_name !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Customer chain name.')
        return
    }
    
    if (!isValidSpecialCharacter(editData.customer_group) && editData.customer_group !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Customer group.')
        return
    }

    if (!allowedActiveInput.includes(editData.active) && editData.active !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid value for active. It must be either Yes or No.')
        return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/CustomerChains/updateCustomerChain.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  const handleCustomerTypeChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, customer_type_array: selectValue, customer_type: selectValue.customer_type}))
    }
  }

  const getCustomerTypes = async (ignore) => {
    setLoadingustomerTypes(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerTypes/getCustomerTypesForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerTypes(response.data);
        }
    setLoadingustomerTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerTypes) {
      return undefined
    }

    getCustomerTypes(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerTypes]);


  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Type</DialogTitle>
        <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Customer chain code*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, chain_code: e.target.value }))}
                  value={editData.chain_code}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Customer chain name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, chain_name: e.target.value }))}
                  value={editData.chain_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Customer chain group"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, customer_group: e.target.value }))}
                  value={editData.customer_group}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Active*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, active: e.target.value }))}
                  value={editData.active}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openCustomerTypes}
                  onOpen={() => {setOpenustomerTypes(true);}}
                  onClose={() => {setOpenustomerTypes(false);}}
                  loading={loadingCustomerTypes}
                  onChange={handleCustomerTypeChange}
                  options={customerTypes}
                  value={editData.customer_type_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer type*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCustomerTypes ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOnEditOrDelete((prev) => !prev)
              setOpenMessageDialog(false)
            }}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditCustomerChain