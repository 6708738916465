import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useAuth from '../../hooks/UseAuth';

function AddAssets({uniqueCode, setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [asset, setAsset] = useState({
    code: '',
    asset_jde_no: '',
    description: '',
    asset_type_size: '',
    warehouse_unique_code: '',
    asset_models_array: [],
    asset_model: '',
    status_name: '',
    store_name: '',
    acquisition_date: '',
    acquisition_value: '',
    net_book_value: '',
    serial_no: '',
    account_code: '',
    account_unique_code: '',
    asset_type_code: '',
  });

  const [assetTypes, setAssetTypes] = useState([]);
  const [openAssetTypes, setOpenAssetTypes] = useState(false);
  const [loadingAssetType, setLoadingAssetType] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouses, setOpenWarehouses] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [assetModels, setAssetModels] = useState([]);
  const [openAssetModels, setOpenAssetModels] = useState(false);
  const [loadingAssetModels, setLoadingAssetModels] = useState(false);
  const [openAssetStatus, setOpenAssetStatus] = useState(false);
  const [assetStatus, setAssetStatus] = useState([]);
  const [assetStatusLoading, setAssetStatusLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [openStoreData, setOpenStoreData] = useState(false);
  const [loadingStoreData, setLoadingStoreData] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!asset.code) {
        setOpenMessageDialog(true);
        setMessage('Equipment NO. is required')
        return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetsRegistry/addAsset.json',
              data: objectToFormData(asset),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const getAssetTypeSize = async (ignore) => {
    try {
        setLoadingAssetType(true)
        const options = {
            method: 'POST',
            url: '/AssetSizes/getAssetSizesForRequest.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetTypes(response.data);
          setLoadingAssetType(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getWarehouses = async (ignore) => {
    try {
        setLoadingWarehouses(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }

        const response = await axios(options);
        if(!ignore){
          setWarehouses(response.data);
          setLoadingWarehouses(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetModels = async (ignore) => {
    try {
        setLoadingAssetModels(true)
        const options = {
            method: 'POST',
            url: '/AssetModels/getAssetModelsNameAndCode.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssetModels(response.data);
          setLoadingAssetModels(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getAssetStatus = async (ignore) => {
    try {
      setAssetStatusLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetStatus/getAssetStatusNameAndCode.json',
        }

        const response = await axios(options);
        if(!ignore){
          setAssetStatus(response.data);
          setAssetStatusLoading(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }


  const getStoreData = async (ignore) => {
    try {
        setLoadingStoreData(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getAllStoresForRequest.json',
            data: {unique_code : uniqueCode}
        }

        const response = await axios(options);
        if(!ignore){
          setStoreData(response.data);
          setLoadingStoreData(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const resetForm = () => {
    setAsset({
      code: '',
      asset_jde_no: '',
      description: '',
      asset_type_size: '',
      warehouse_unique_code: '',
      asset_models_array: [],
      asset_model: '',
      status_name: '',
      store_name: '',
      acquisition_date: '',
      acquisition_value: '',
      net_book_value: '',
      serial_no: '',
      account_code: '',
      account_unique_code: '',
      asset_type_code: '',
    });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setAsset((prev) => ({...prev, image: file}));
  };

  const handleAssetTypeChange = (e, selectValue) => {
      if (selectValue) {
        setAsset((prevAccount) => ({ ...prevAccount, asset_type_size: selectValue.label}))
      }
  }
  const handleWarehouseChange = (e, selectValue) => {
      if (selectValue) {
        setAsset((prevAccount) => ({ ...prevAccount, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_branch: selectValue.warehouse_name}))
      }
  }

  const handleAssetModelsChange = (e, selectValue) => {
    if (selectValue) {
      let asset_model = selectValue.asset_model;
      setAsset((prevAccount) => ({
        ...prevAccount, 
        asset_model: asset_model, 
        asset_models_array: selectValue
      }))
    }
  }

  const handleAssetStatusChange = (e, selectValue) => {
    if (selectValue) {
      setAsset((prevData) => ({ ...prevData, status_name: selectValue.asset_status}))
    }
  }

  const handleStoreAccontsChange = (e, selectValue) => {
    if (selectValue) {
      setAsset((prevData) => ({ ...prevData, store_name: selectValue.account_name, account_code: selectValue.account_code, account_unique_code: selectValue.account_unique_code}))
    }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssetTypes) return undefined
    getAssetTypeSize(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetTypes]);

  useEffect(()=>{
    let ignore = false;

    if (!openWarehouses) return undefined
    getWarehouses(ignore);

    return () => {
      ignore = true;
    }
  }, [openWarehouses]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetModels) return undefined
    getAssetModels(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetModels]);

  useEffect(()=>{
    let ignore = false;

    if (!openAssetStatus) return undefined
    getAssetStatus(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssetStatus]);

  useEffect(()=>{
    let ignore = false;

    if (!openStoreData) return undefined
    getStoreData(ignore);

    return () => {
      ignore = true;
    }
  }, [openStoreData]);


  return (
    <div>
    <Button 
      variant="contained"
      size="small" 
      onClick={handleOpen}
      disabled={Number(auth.role) !== 0}
      >Add Asset</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Asset</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  name="code"
                  label="Equipment no.*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    const input = e.target.value;
                    // Regular expression to allow up to 11 digits
                    if (/^\d{0,64}$/.test(input)) {
                      setAsset((prevData) => ({ ...prevData, code: input }));
                    }
                  }}
                  value={asset.code}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Asset JDE No"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, asset_jde_no: e.target.value }))}
                  value={asset.asset_jde_no}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetModels}
                  onOpen={() => {setOpenAssetModels(true);}}
                  onClose={() => {setOpenAssetModels(false);}}
                  loading={loadingAssetModels}
                  onChange={handleAssetModelsChange}
                  options={assetModels}
                  value={asset.asset_models_array}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => (option && value && option.label === value.label)}
                  renderInput={(params) => <TextField {...params} label="Asset Model*" />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        open={openAssetStatus}
                        onOpen={() => {setOpenAssetStatus(true);}}
                        onClose={() => {setOpenAssetStatus(false);}}
                        loading={assetStatusLoading}
                        onChange={handleAssetStatusChange}
                        options={assetStatus}
                        value={assetStatus.label}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.label === value.label}
                        renderInput={(params) => <TextField {...params} label="Asset Status*" />}
                      />
                    </FormControl>
                  </Grid>
            <Grid item xs={12} mb={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openWarehouses}
                  onOpen={() => {setOpenWarehouses(true);}}
                  onClose={() => {setOpenWarehouses(false);}}
                  loading={loadingWarehouses}
                  onChange={handleWarehouseChange}
                  options={warehouses}
                  value={warehouses.label}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} label="Warehouse Branch*" />}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openStoreData}
                  onOpen={() => {setOpenStoreData(true);}}
                  onClose={() => {setOpenStoreData(false);}}
                  loading={loadingStoreData}
                  onChange={handleStoreAccontsChange}
                  options={storeData}
                  value={storeData.label}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Store/Business name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingStoreData ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Acquisition Date"
                    value={asset.acquisition_date ? dayjs(asset.acquisition_date) : null}
                    onChange={(newValue) => setAsset({
                      ...asset,
                      acquisition_date: newValue ? dayjs(newValue).format('YYYY-MM-DD') : null
                    })}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                  name="acquisition_value"
                  label="Acquisition Value"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, acquisition_value: e.target.value }))}
                  value={asset.acquisition_value}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="net_book_value"
                  label="Net Book Value"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, net_book_value: e.target.value }))}
                  value={asset.net_book_value}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="serial_no"
                  label="Serial No"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAsset((prevAccount) => ({ ...prevAccount, serial_no: e.target.value }))}
                  value={asset.serial_no}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openAssetTypes}
                  onOpen={() => {setOpenAssetTypes(true);}}
                  onClose={() => {setOpenAssetTypes(false);}}
                  loading={loadingAssetType}
                  onChange={handleAssetTypeChange}
                  options={assetTypes}
                  value={assetTypes.asset_type_code}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.value}
                  renderInput={(params) => <TextField {...params} label="Asset Type size" />}
                />
              </FormControl>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {asset.image && (
                  <p>{asset.image.name}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAssets