import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import { Edit } from '@mui/icons-material';
import useTransferAssetRequestContext from '../../hooks/UseTransferAssetRequestContext';


const EditTransferAssetRequest = ({transferRequests, setDoneUpdateRequest}) => {
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useTransferAssetRequestContext();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [destinationWarehouses, setDestinationWarehouses] = useState([]);
  const [openDestinationWarehouse, setOpenDestinationWarehouse] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [transferRequest, setTransferRequest] = useState({
    assetSelected: [],
    old_asset_code: '',
    new_asset_code: '',
    warehouseSelected: [],
    destinationWarehouseSelected: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    destination_warehouse_unique_code: '',
    destination_warehouse_name: '',
    hauling_rates: [],
    hauling_rate_name : '',
    hauling_rate_unique_code : '',
    hauling_rate : ''
  });

  const [haulingRates, setHaulingRates] = useState([]);
  const [openHaulingRate, setOpenHaulingRate] = useState(false);
  const [haulingLoading, setHaulingLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const {
      request_code,
      assets,
      origin_warehouse_name,
      origin_warehouse_unique_code,
      destination_warehouse_name,
      destination_warehouse_unique_code,
      hauling_rate_name,
      hauling_rate_unique_code,
      hauling_rate
    } = transferRequests;
    let assetCode = assets.split(",");

    const assetSelectedArray = assetCode.map((asset, index) => ({
      label: asset,
      asset_code: asset
    }));

    setTransferRequest((prevData) => ({ 
      ...prevData,
      assetSelected: assetSelectedArray,
      old_asset_code: assets,
      warehouse_unique_code: origin_warehouse_unique_code,
      warehouse_name: origin_warehouse_name,
      destination_warehouse_unique_code: destination_warehouse_unique_code,
      destination_warehouse_name: destination_warehouse_name,
      request_code: request_code,
      warehouseSelected: {'label' : `${origin_warehouse_name} - (${origin_warehouse_unique_code})`, 'value': origin_warehouse_unique_code},
      destinationWarehouseSelected: {'label' : `${destination_warehouse_name} - (${destination_warehouse_unique_code})`, 'value': destination_warehouse_unique_code},
      hauling_rates: {'label' : hauling_rate_name, 'hauling_rate_unique_code': hauling_rate_unique_code, hauling_rate: hauling_rate},
    }))
  }
  const handleClose = () => {
    setOpen(false)
  }

  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      let asset_code = selectValue.map(item => item.asset_code);
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, assetSelected: selectValue, new_asset_code: asset_code})
      )
    } 
    setTransferRequest((prevAccount) => ({ ...prevAccount, assetSelected: [], new_asset_code: ''}))
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }
  const hanldeDestinationWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setTransferRequest(
        (prevAccount) => ({ ...prevAccount, destinationWarehouseSelected: selectValue, destination_warehouse_unique_code: selectValue.warehouse_unique_code, destination_warehouse_name: selectValue.warehouse_name})
      )
    } 

    setTransferRequest((prevAccount) => ({ ...prevAccount, destinationWarehouseSelected: [], warehouse: []}))
  }

  const hanldeHaulingRequestsChange = (e, selectValue) => {
    if (selectValue) {
      setTransferRequest((prevAccount) => ({ ...prevAccount, hauling_rates: selectValue, hauling_rate_name: selectValue.label, hauling_rate_unique_code: selectValue.unique_code, hauling_rate: selectValue.rate}))
    }
  }

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getDestinationWarehouses = async(ignore) => {
    try {
      setDestinationLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getDestinationWarehouses.json',
            data: {warehouse_unique_code: transferRequest.warehouse_unique_code}
        }
        const response = await axios(options);
        if (!ignore) {
          setDestinationWarehouses(response.data);
        }
        setDestinationLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  
  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForTransferRequest.json',
            data: {warehouse_unique_code: transferRequest.warehouse_unique_code}
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getHaulingRates = async (active) => {
    try {
      setHaulingLoading(true)
      const options = {
          method: 'POST',
          url: '/HaulingRates/getHaulingRatesForAutocomplete.json',
      }
      const response = await axios(options);
      if (active) {
        setHaulingRates(response.data);
      }
      setHaulingLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openAssets) {
      setAssets([]);
    }

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  useEffect(() => {
    let ignore = false;
    if (!openDestinationWarehouse) {
      setDestinationWarehouses([]);
    }

    if (!openDestinationWarehouse) return undefined
    getDestinationWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openDestinationWarehouse]);

  useEffect(() => {
    let active = true;

    if (!openHaulingRate) {
      return undefined;
    }

    getHaulingRates(active);

    return () => {
      active = false;
    };

  }, [openHaulingRate]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (transferRequest.warehouseSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Warehouse is required.');
      return false;
    }
    if (transferRequest.assetSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset is required.');
      return false;
    }
    if (transferRequest.destinationWarehouseSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Destination Warehouse is required.');
      return false;
    }

    try {

      const options = {
          method: 'POST',
            url: '/TransferAssetRequests/UpdateRequest.json',
            data: objectToFormData(transferRequest),
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setDoneUpdateRequest((prev) => !prev)
        setDoneUpdate((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setTransferRequest({
      assetSelected: [],
      asset_code: '',
      warehouseSelected: [],
      destinationWarehouseSelected: [],
      warehouse_unique_code: '',
      warehouse_name: '',
      destination_warehouse_unique_code: '',
      destination_warehouse_name: '',
      hauling_rates: [],
      hauling_rate_name : '',
      hauling_rate_unique_code : '',
      hauling_rate : ''
    });
  };

  return (
    <>
      <Button 
        disabled={transferRequests.status === 0 ? false : true} 
        variant='contained' 
        size="small" 
        color='info' 
        startIcon={<Edit />}
        onClick={handleOpen}
      >Edit
      </Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New Service request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={transferRequest.warehouseSelected}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Centigen Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="combo-box-demo"
                open={openAssets}
                onOpen={() => {setOpenAssets(true);}}
                onClose={() => {setOpenAssets(false);}}
                loading={loading}
                onChange={hanldeAssetsChange}
                options={assets}
                value={transferRequest.assetSelected}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select asset*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openDestinationWarehouse}
                onOpen={() => {setOpenDestinationWarehouse(true);}}
                onClose={() => {setOpenDestinationWarehouse(false);}}
                loading={destinationLoading}
                onChange={hanldeDestinationWarehouseChange}
                options={destinationWarehouses}
                value={transferRequest.destinationWarehouseSelected}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Destination Warehouse*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {destinationLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openHaulingRate}
                      onOpen={() => {setOpenHaulingRate(true);}}
                      onClose={() => {setOpenHaulingRate(false);}}
                      onChange={hanldeHaulingRequestsChange}
                      options={haulingRates}
                      value={transferRequest.hauling_rates}
                      loading={haulingLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hauling Rate*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {haulingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default EditTransferAssetRequest