import { Box, Chip, Grid, StyledEngineProvider } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddTransferRequest from './AddTransferRequest';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import useSortModel from '../../hooks/UseSortModel';



const StoreToStoreTransfers = () => {
  const axios = AxiosCustomConfig();
  const [onEdit, setOnEdit] = useState(false);
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'store_to_store_transfer';
  const modelTable = 'StoreToStoreTransfers';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    // { field: 'id', headerName: 'Id', width: 50, minWidth: 20, wrap: true,
    // },
    { field: 'request_code', headerName: 'Transfer #',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/storeToStoreTransfers/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
        field: 'status', 
        headerName: 'Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
          
            const statusName = params.value;
            if (statusName === 0) {
              return <Chip label='For approval' sx={{ backgroundColor: '#35A7CA' }}  />;
            } else if (statusName === 1) {
              return <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4' }} />;
            } else if (statusName === 2) {
              return <Chip label='Deployed' sx={{ backgroundColor: '#3AD658' }} />;
            } else if (statusName === 3) {
              return <Chip label='Unproductive' sx={{ backgroundColor: '#E8484F', color: '#fff' }} />;
            } else if (statusName === 4) {
              return <Chip label='Unproductive & cancelled' sx={{ backgroundColor: '#843B10', color: '#fff' }} />;
            } else if (statusName === 5) {
              return <Chip label='Hold' sx={{ backgroundColor: '#F8FD6A' }} />;
            } else if (statusName === 6) {
              return <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />;
            } else if (statusName === 7) {
              return <Chip label='Rejected' sx={{ backgroundColor: '#000001', color: '#fff' }} />;
            } else {
              return '';
            }
            
        }
    },
    { field: 'asset_name', headerName: 'Assets',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'requestor_name', headerName: 'Requested by',flex: 1, minWidth: 150,},
    { field: 'deployment_type', headerName: 'Deployment type',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Date Uploaded',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/StoreToStoreTransfers/getAllTransferRequests.json',
        }
        const response = await axios(options);
        const data = response.data;

        setAssetDisposalData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEdit]);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/StoreToStoreTransfers/getAllTransferRequestsId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AddTransferRequest setOnEdit={setOnEdit}/>
      </Box>
      
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={assetDisposalData}
        columns={filteredAndOrderedColumns}
        getRowHeight={() => 'auto'}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['asc', 'desc']}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar
            selectedRows={rowSelectionModel} 
            columns={columns}
            setOnSaveColumns={setOnSaveColumns}
            functionName={functionName}
            modelTable={modelTable}
            handleClickAllData={handleClickAllData}
            selectAll={selectAll}
          />,
          footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default StoreToStoreTransfers