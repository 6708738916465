import { useTheme } from '@emotion/react';
import { CheckCircleOutline } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import useStoreToStoreContext from '../../hooks/UseStoreToStoreContext';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';


function DeployTransferRequest({requestDetails, setDoneUpdateRequest}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useStoreToStoreContext();
  const [open, setOpen] = useState(false);
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    pullout_date: '',
    completion_date: '',
    origin_account_name: '',
    destination_account_name: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id, origin_account_name, destination_account_name} = requestDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code || prevData.request_code, 
      id: id || prevData.id,
      origin_account_name: origin_account_name || prevData.origin_account_name,
      destination_account_name: destination_account_name || prevData.destination_account_name,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    try{
      setLoading(true);
      setShowProgress(true);

      const options = {
          'method': 'POST',
          'url' : '/StoreToStoreTransfers/closedRequest.json',
          'data': objectToFormData(requests)
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate((prev) => !prev)
          setDoneUpdateRequest((prev) => !prev)
          setOpen(false)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }

  };

  const validateRequiredFields = () => {
    const requiredFields = ['completion_date', 'pullout_date'];

    for (const field of requiredFields) {
      if (!requests[field]) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }
    }

    return true;
  };
  const handleClose = () => {
    setOpen(false)
  };

  return (
    <>
      <Button 
        disabled={requestDetails.status === 2 || requestDetails.status === 0 ? true : false} 
        variant='contained' 
        size="small" 
        color='success' 
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        Deploy
      </Button>

      <Dialog 
        open={open} 
        PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
        fullWidth={true} 
        maxWidth={'md'}
        >
      <DialogTitle>Close request</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Date served:</Typography>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Pull out date*"
                            value={requests.pullout_date}
                            onChange={(newValue) => setRequests({...requests, pullout_date: newValue})}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="initial">Date completion:</Typography>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Completion Date*"
                            value={requests.completion_date}
                            onChange={(newValue) => setRequests({...requests, completion_date: newValue})}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <FormControl fullWidth>
          <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default DeployTransferRequest