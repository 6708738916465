import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddAssetServices({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [assetService, setAssetService] = useState({
    name: '',
    description: '',
    price: '',
    business_unit: '',
    repair_type_name: '',
    repair_types: [],
  });

  const [repairTypes, setRepairTypes] = useState([]);
  const [openRepairTypes, setOpenRepairTypes] = useState(false);
  const [loadingRepairTypes, setLoadingRepairTypes] = useState(false);
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(assetService.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Service Name.')
        return
    }

    if (!isValidSpecialCharacter(assetService.description)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }
    if (Number(assetService.price) <= 0) {
      setOpenMessageDialog(true);
      setMessage('Invalid price. Price should be greated than or equal to one.')
      return
    }
    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AssetServices/addAssetService.json',
              data: objectToFormData(assetService),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }


  const resetForm = () => {
    setAssetService({
      name: '',
      description: '',
      price: '',
      repair_type_name: '',
      business_unit: '',
      repair_types: [],
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = ['name', 'price', 'repair_type_name'];

    for (const field of requiredFields) {
      if (!assetService[field] || (Array.isArray(assetService[field]) && assetService[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisk (*) are required');
        return false;
      }
    }

    return true;
  };

  const handleRepairTypeChange = (e, selectValue) => {
    if (selectValue) {
      setAssetService((prevAccount) => ({ ...prevAccount, repair_types: selectValue, repair_type_name: selectValue.repair_type_name}))
    }
  }

  const getRepairTypes = async (active) => {
    try {
      setLoadingRepairTypes(true)
      const options = {
          method: 'POST',
          url: '/RepairTypes/getRepairTypesForAutocomplete.json',
      }
      const response = await axios(options);
      if (active) {
        setRepairTypes(response.data);
      }
      setLoadingRepairTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let active = true;

    if (!openRepairTypes) {
      return undefined;
    }

    getRepairTypes(active);

    return () => {
      active = false;
    };

  }, [openRepairTypes]);
  
  useEffect(() => {
    if (!openRepairTypes) {
      setRepairTypes([]);
    }
  }, [openRepairTypes]);

  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Asset Service</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Asset Service</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetService((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={assetService.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetService((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={assetService.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="price"
                  label="Price*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setAssetService((prevAccount) => ({ ...prevAccount, price: input }));
                          }
                      }
                  }}
                  value={assetService.price}
              />
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openRepairTypes}
                      onOpen={() => {setOpenRepairTypes(true);}}
                      onClose={() => {setOpenRepairTypes(false);}}
                      onChange={handleRepairTypeChange}
                      options={repairTypes}
                      value={repairTypes.label}
                      loading={loadingRepairTypes}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Repair Type*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingRepairTypes ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="business_unit"
                  label="Business unit"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setAssetService((prevAccount) => ({ ...prevAccount, business_unit: e.target.value }))}
                  value={assetService.business_unit}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddAssetServices