import { Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ProcessDialog from './ProcessDialog';
import MessageDialog from './MessageDialog';
import { PictureAsPdf, Print } from '@mui/icons-material';
import { PDFDocument , rgb , StandardFonts } from 'pdf-lib';
import logo from '../../images/logo.png'


function TapsPrint({ requestDetails }) {
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');
  const [noTaps, setNoTaps] = useState(false);
  const [pdfContent, setPdfContent] = useState(null); // To store the filled PDF


  useEffect(() => {
    const fetchData = async () => {
        
        if (!requestDetails || Object.keys(requestDetails).length === 0) {
            return; // Exit if requestDetails is empty or not yet available
        }

        const { pdfDoc, page } = await createPdfDocument();
        const { boldFont, regularFont } = await loadFonts(pdfDoc);

        await drawLogoAndAddress(page, logo, pdfDoc, boldFont, regularFont);

        const margin = 20; // Margin from the edges
        const rectangleY = page.getHeight() - 190; // Position the rectangle below the logo
        const rectangleHeight = 34; // Height of the rectangle
        const rectangleWidth = page.getWidth() - margin * 2; // Full width of the page minus margins

        page.drawRectangle({
          x: margin,
          y: rectangleY,
          width: rectangleWidth,
          height: rectangleHeight,
          color: rgb(0.9, 0.9, 0.9), 
          borderColor: rgb(0, 0, 0), 
          borderWidth: 1,
        });

        const titleText = 'TRADE ASSET PULLOUT SLIP';
          const titleFontSize = 12;
          const titleWidth = boldFont.widthOfTextAtSize(titleText, titleFontSize);
          const titleX = margin + (rectangleWidth - titleWidth) / 2; 
          const titleY = rectangleY + (rectangleHeight / 2) - (titleFontSize / 2); 
          page.drawText(titleText, {
            x: titleX,
            y: titleY,
            size: titleFontSize,
            font: boldFont,
            color: rgb(0, 0, 0), 
          });

          drawLabeledText(page, 'Pull out asset from:', requestDetails.account_code, 29, 600, 12, boldFont, regularFont);
          drawLabeledText(page, 'Store name:', requestDetails.account_name, 29, 581, 12, boldFont, regularFont);
          drawLabeledText(page, 'Contact person:' , requestDetails.store_contact_person , 29, 564, 12, boldFont, regularFont);
          drawLabeledText(page, 'Contact No:', requestDetails.store_contact_no , 29, 548, 12, boldFont, regularFont);
          drawLabeledText(page, 'Complete address:', requestDetails.business_address, 29, 530, 12, boldFont, regularFont);
          drawLabeledText(page, 'Service notification no:', requestDetails.request_code , 360, 600, 12, boldFont, regularFont);
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const day = String(today.getDate()).padStart(2, '0');
          
          const currentDate = `${year}-${month}-${day}`;
          
          drawLabeledText(page, 'Delivery date:', currentDate , 360, 581, 12, boldFont, regularFont);
          drawLabeledText(page, 'Distributors\'s name:', requestDetails.branch_name, 360, 564, 12, boldFont, regularFont);
          drawLabeledText(page, 'Pulled out assets :', '', 29, 510, 12, boldFont, regularFont);

          const tableData = [
            ['Equipment No', 'Asset model', 'Asset type size',],
            ['' , requestDetails.model , requestDetails.asset_type_size]
             
          ];

          const rows = 2
          const columnWidths = [170, 184, 184];
          const rowHeight = 20;
          const numRows = rows;

        await drawTable(page, 30, 500, columnWidths, rowHeight, numRows, tableData, pdfDoc, drawFooter);
        
        const pdfBytes = await pdfDoc.save();
        const filledPdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const filledPdfUrl = URL.createObjectURL(filledPdfBlob);
        setPdfContent(filledPdfUrl);

        setContent(requestDetails.taps_copy_display);
        setNoTaps(false);

    };
  
    fetchData();
  }, [requestDetails.taps_copy_display]);
  
  const loadFonts = async (pdfDoc) => {
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    return { boldFont, regularFont };
  };
  

  const createPdfDocument = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([595, 842]); // A4 dimensions
    return { pdfDoc, page };
  };
  

  const drawLabeledText = (page, label, value, x, y, fontSize, boldFont, regularFont) => {

    page.drawText(label, {
      x, y, size: fontSize, font: boldFont, color: rgb(0, 0, 0),
    });
    const textX = x + boldFont.widthOfTextAtSize(label, fontSize) + 5;
    page.drawText(value, {
      x: textX, y, size: fontSize, font: regularFont, color: rgb(0, 0, 0),
    });

  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrintPDF = async () => {
    if (pdfContent) {
        // Directly download the PDF
        const link = document.createElement('a');
        link.href = pdfContent;
        link.download = 'taps.pdf';
        link.click();

    }
  };

  const handlePrintWord = () => {
    if (pdfContent) {
      const link = document.createElement('a');
      link.href = pdfContent;
      link.download = 'taps.docx';
      link.click();
    }
  };
  
  const drawFooter = (page , boldFont, regularFont) => {

    const marginLeft = 34; // Margin from the left side
    const marginRight = 34; // Margin from the right side
    const marginBottom = 50; // Margin from the bottom
    const pageWidth = page.getWidth(); // Get the width of the page
    const footerY = marginBottom + 80; // Adjust this to control vertical positioning of the footer content (above the margin)

    // Calculate box width based on page width and margins
    const availableWidth = pageWidth - marginLeft - marginRight;
    const boxWidth = availableWidth / 3; // Three boxes with space between them
    const boxHeight = 100; // Height of the boxes
    const boxY = footerY - 100 - 23; // Vertical position of the boxes

    // Draw first box for "Prepared by:"
    const firstBoxX = marginLeft; // X position for the first box (with left margin)
    page.drawRectangle({
        x: firstBoxX,
        y: boxY,
        width: boxWidth,
        height: boxHeight,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
    });

    // Draw "Prepared by:" text centered in the top portion of the first box
    const preparedByLabel = 'Prepared by:';
    const textWidthPrepared = boldFont.widthOfTextAtSize(preparedByLabel, 12);
    const textXPrepared = firstBoxX + (boxWidth - textWidthPrepared) / 2; // Center the text
    page.drawText(preparedByLabel, {
        x: textXPrepared,
        y: boxY + boxHeight - 20, // Position at top center of the box
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Add a line for the signature in the first box
    const signatureY = boxY + boxHeight - 50;
    const signatureLineWidth = 120;
    const signatureX = firstBoxX + (boxWidth - signatureLineWidth) / 2;
    page.drawText('______________________', {
        x: signatureX,
        y: signatureY,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('(Repair/Warehouse Representative)', {
        x: signatureX - 20,
        y: signatureY - 20,
        size: 9.4,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Draw second box for "Pullout by:"
    const secondBoxX = firstBoxX + boxWidth; // X position for the second box
    page.drawRectangle({
        x: secondBoxX,
        y: boxY,
        width: boxWidth,
        height: boxHeight,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
    });

    // Draw "Pullout by:" text centered in the top portion of the second box
    const pulloutByLabel = 'Pullout by:';
    const textWidthPullout = boldFont.widthOfTextAtSize(pulloutByLabel, 12);
    const textXPullout = secondBoxX + (boxWidth - textWidthPullout) / 2; // Center the text
    page.drawText(pulloutByLabel, {
        x: textXPullout,
        y: boxY + boxHeight - 20, // Position at top center of the box
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Add a line for the signature in the second box
    page.drawText('______________________', {
        x: signatureX + boxWidth, // Move the signature X position to the second box
        y: signatureY + 8,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('(Hauler)', {
        x: signatureX + boxWidth + 40, // Move the text X position to the second box
        y: signatureY - 4,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('______________________', {
      x: signatureX + boxWidth, // Move the signature X position to the second box
      y: signatureY - 24,
      size: 10,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    page.drawText('Driver \'s name / Plate no.', {
        x: signatureX + boxWidth + 4, // Move the text X position to the second box
        y: signatureY - 38,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    // Draw third box for "Approved by:"
    const thirdBoxX = secondBoxX + boxWidth; // X position for the third box
    page.drawRectangle({
        x: thirdBoxX,
        y: boxY,
        width: boxWidth,
        height: boxHeight,
        borderWidth: 1,
        borderColor: rgb(0, 0, 0),
    });

    // Draw "Approved by:" text centered in the top portion of the third box
    const approvedByLabel = 'Acknowledged by:';
    const textWidthApproved = boldFont.widthOfTextAtSize(approvedByLabel, 12);
    const textXApproved = thirdBoxX + (boxWidth - textWidthApproved) / 2; // Center the text
    page.drawText(approvedByLabel, {
        x: textXApproved,
        y: boxY + boxHeight - 20,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    // Add a line for the signature in the third box
    page.drawText('________________________', {
        x: signatureX + boxWidth * 2, // Move the signature X position to the third box
        y: signatureY + 8,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });
    // x: signatureX + boxWidth + 40, // Move the text X position to the second box
    // y: signatureY - 4,
    page.drawText('Authorized Person', {
        x: signatureX + boxWidth * 2 + 20, // Move the text X position to the third box
        y: signatureY - 4,
        size: 10,
        font: boldFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('________________________', {
        x: signatureX + boxWidth * 2, // Move the signature X position to the third box
        y: signatureY - 24,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

    page.drawText('Signature / date', {
        x: signatureX + boxWidth + 204, // Move the text X position to the second box
        y: signatureY - 38,
        size: 10,
        font: regularFont,
        color: rgb(0, 0, 0),
    });

};

  async function drawTable(page, startX, startY, columnWidths, rowHeight, numRows, data, pdfDoc, drawFooterFn) {

    const numberOfColumns = columnWidths.length;
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    
    let currentPage = page;
    let currentY = startY;
    const pageHeight = page.getHeight();
    const footerHeight = 120; // Height reserved for the footer

    for (let i = 0; i < numRows; i++) {
        // Check if the next row will fit on the current page
        if (currentY - rowHeight < footerHeight) {
            // Draw the bottom border of the last row on the current page
            currentPage.drawLine({
                start: { x: startX, y: currentY },
                end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            // Create a new page
            currentPage = pdfDoc.addPage();
            currentY = pageHeight - 20; // Reset Y position on new page

            // Draw the top border of the first row on the new page
            currentY -= rowHeight; // Move Y position down for the first row on the new page
        }

        // Draw the top border of the row
        currentPage.drawLine({
            start: { x: startX, y: currentY },
            end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
            thickness: 1,
            color: rgb(0, 0, 0),
        });

        // Move Y position down for the next row
        currentY -= rowHeight;

        // Draw vertical lines for columns
        let currentX = startX;
        for (let j = 0; j < numberOfColumns; j++) {
            // Draw vertical line for each column's left border
            currentPage.drawLine({
                start: { x: currentX, y: currentY + rowHeight }, // Adjusted Y position
                end: { x: currentX, y: currentY },
                thickness: 1,
                color: rgb(0, 0, 0),
            });

            // Add the text if data exists
            if (data[i] && data[i][j]) {
                const font = i === 0 ? boldFont : regularFont; // Use bold font for the first row (header)
                currentPage.drawText(data[i][j], {
                    x: currentX + 5,
                    y: currentY + 5,
                    size: 10,
                    font: font,
                    color: rgb(0, 0, 0),
                });
            }

            // Move to the right for the next column
            currentX += columnWidths[j];

            // Draw the right border of the last column (column 6)
            if (j === numberOfColumns - 1) {
                currentPage.drawLine({
                    start: { x: currentX, y: currentY + rowHeight }, // Adjusted Y position
                    end: { x: currentX, y: currentY },
                    thickness: 1,
                    color: rgb(0, 0, 0),
                });
            }
        }
    }

    // Draw the bottom border of the last row on the last page
    currentPage.drawLine({
        start: { x: startX, y: currentY },
        end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
        thickness: 1,
        color: rgb(0, 0, 0),
    });

    // Draw the last vertical line to close the right border of the table
    currentPage.drawLine({
        start: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: startY },
        end: { x: startX + columnWidths.reduce((a, b) => a + b, 0), y: currentY },
        thickness: 1,
        color: rgb(0, 0, 0),
    });

    // Draw the footer for the last page
    drawFooterFn(currentPage , boldFont , regularFont); 

  }

  function formatDate(dateString) {

    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    let day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
      return `${year}-${month}-${day}`;

  }

  const drawLogoAndAddress = async (page, logo, pdfDoc, boldFont, regularFont) => {
    const imageBytes = await fetch(logo).then((res) => res.arrayBuffer());
    const embeddedImage = await pdfDoc.embedPng(imageBytes);
    const { width, height } = embeddedImage.scale(0.5); // Scale down the image
  
    const logoX = 50;
    const logoY = page.getHeight() - height - 50;
    
    page.drawImage(embeddedImage, {
      x: logoX, y: logoY, width, height,
    });
  
    const textX = logoX + width + 20;
    const textY = logoY + height - 10;
    
    page.drawText('FRONERI PHILIPPINES, INC.\nNational Highway,\nBarangay Tibag,\nPullilan, Philippines', {
      x: textX, y: textY, size: 10, font: boldFont, lineHeight: 12, color: rgb(0, 0, 0),
    });
  
    const hotlineY = textY - 48;
    page.drawText('Trade, Asset hotline:', {
      x: textX, y: hotlineY, size: 10, font: boldFont, color: rgb(0, 0, 0),
    });
  
    page.drawText('0922 882 6014 / 0920 928 7670\n/ 0917 516 6153', {
      x: textX + 110, y: hotlineY, size: 10, font: regularFont, color: rgb(0, 0, 0),
    });
  };

  

  

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Print TAPS</Button>
      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle>Print TAPS</DialogTitle>
        <DialogContent>
          {noTaps ? (
            <Typography variant="h6">No TAPS available</Typography>
          ) : 
            <iframe
              src={pdfContent}
              title="PDF"
              width="100%"
              height="500px"
            />
        }
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handlePrintPDF} 
            color="primary" 
            startIcon={<PictureAsPdf />}
            disabled={noTaps}
          >
            Print as PDF
          </Button>
          <Button 
            onClick={handlePrintWord} 
            color="secondary" 
            startIcon={<Print />}
            disabled={noTaps}
          >
            Print as Word
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={false} ContentText="Loading Please Wait..." />
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={false}
          handleClose={() => {}}
          message=""
        />
      </StyledEngineProvider>
    </div>
  );
}

export default TapsPrint;
