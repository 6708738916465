import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, StyledEngineProvider} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Papa from 'papaparse';
import CSVTemplateDownloadButton from '../CSVTemplateDownloadButton';
import MessageDialog from '../Utils/MessageDialog';
import { FileUpload } from '@mui/icons-material';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CsvStatusDialog from '../Utils/CsvStatusDialog';

const AddAccountsForDistributors = ({setOnEditOrDelete}) => {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');

  const [csvData, setCSVData] = useState(null); // State to store parsed CSV data
  const [csvUploadPercent, setCsvUploadPercent] = useState(0);
  const [uploadStatusArray, setUploadStatusArray] = useState([]);
  const [doneUploadingProduct, setDoneUploadingProduct] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [showCsvStatusDialog, setShowCsvStatusDialog] = useState(false);
  const [showUploadStatusDialog, setShowUploadStatusDialog] = useState(false);
  const fileInputRef = useRef(null);

  const objectToFormData = (object) => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());


  const handleCancel = () => {
    setOpen(false);
    setCSVData(null)
  };

  const onInputChange = (event) =>{
    let file = event.target.files;
    setCSVData(file);
  };

  const generateCSVContent = () => {
    // Generate CSV content with default header
    const header = "Username*,Distributor Id*,Store/Business Name*,Customer No*,Distributor/ Chain acct*,Region,Contact No,Customer Type,Customer details,CSID,Ship to/Business Address,Residential Address,Owner/Manager/Buyer's name,Channel,Distributor Sales Representative,Province,City/Municipality,Barangay,Street,Terms,Discount,Days cover,Frequency,Channel2,Customer Chain Static,Active,Region Str,Distributor/ Account*,Store map";
    return header;
  };

  const handleClose = () => {
    setDoneUploadingProduct(false);
    setOpenMessageDialog(false);
    setOnEditOrDelete(prev => !prev);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const processUploadProductsCsv = async (csvData) => {
    if (csvData && csvData.length > 0) {
      try {
        const file = csvData[0];
        if( file.type !== "text/csv"){
          setOpenMessageDialog(true);
          setMessage('File error. The file must be a csv.');
          return;
        }

        const accountList =  await readCsv(file);
        
        if(accountList.length <=0){
          setOpenMessageDialog(true);
          setMessage("CSV must not empty.");
          return;
        }

        if(accountList.success !== true){
          setOpenMessageDialog(true);
          setMessage(accountList.message);
          return;
        }

        const chunkedData = await chunkUploadData(accountList.data);
        const totalData = Math.ceil(accountList.data.length / 200);

        setModalTitle('Upload Accounts');
        setShowCsvStatusDialog(true)
        insertProducts(0,chunkedData,totalData);

      } catch(error){
        setOpenMessageDialog(true);
        setMessage(error);
      }   
    } else {
      setMessage('File error. Please select csv file.')
      setOpenMessageDialog(true);
    }
  };

  function readCsv(csvFile) {
    return new Promise((resolve, reject) => {
      var listData = [];
      let has_error = 0;
      let return_message = {
        success: false,
        message: '',
        data: []
      }
          Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: false,
            encoding: "ISO-8859-3",
            step:function(row, parser){
              let headers = generateCSVContent().split(',');
              let mismatchedHeaders = [];

              if (headers.length === row.meta.fields?.length) {
                for (var h = 0; h < row.meta.fields?.length; h++) {
                  let csv_header = row.meta.fields[h].trim();
                  

                  if (!headers.includes(csv_header)) {
                    has_error++;
                    mismatchedHeaders.push({ incorrect: csv_header, correct: headers[h] });
                  }
                }

                if (mismatchedHeaders.length > 0) {
                  return_message.message = 'Invalid Headers:\n';
                  mismatchedHeaders.forEach(header => {
                    return_message.message += `Expected "${header.correct}", but found "${header.incorrect}".\n`;
                  });
                  parser.abort();
                }

                if(row.errors.length === 0){
                  var accountData = {};
                  accountData = {
                    username: row.data.hasOwnProperty('Username*') ? row.data['Username*'].trim() : row.data['Username*'],
                    distributor_id: row.data.hasOwnProperty('Distributor Id*') ? row.data['Distributor Id*'].trim() : row.data['Distributor Id*'],
                    account_code: row.data.hasOwnProperty('Customer No*') ? row.data['Customer No*'].trim() : row.data['Customer No*'],
                    account_name: row.data.hasOwnProperty('Store/Business Name*') ? row.data['Store/Business Name*'].trim() : row.data['Store/Business Name*'],
                    branch_code: row.data.hasOwnProperty('Customer No*') ? row.data['Customer No*'] : row.data['Customer No*'],
                    branch_name: row.data.hasOwnProperty('Distributor/ Chain acct*') ? row.data['Distributor/ Chain acct*'].trim() : row.data['Distributor/ Chain acct*'],
                    province: row.data.hasOwnProperty('Province') ? row.data['Province'] : row.data['Province'],
                    city_municipality: row.data.hasOwnProperty('City/Municipality') ? row.data['City/Municipality'].trim() : row.data['City/Municipality'],
                    barangay: row.data.hasOwnProperty('Barangay') ? row.data['Barangay'].trim() : row.data['Barangay'],
                    street: row.data.hasOwnProperty('Street') ? row.data['Street'].trim() : row.data['Street'],
                    contact: row.data.hasOwnProperty('Contact No') ? row.data['Contact No'] : row.data['Contact No'],
                    active: row.data.hasOwnProperty('Active') ? row.data['Active'].trim() : row.data['Active'],
                    csid: row.data.hasOwnProperty('CSID') ? row.data['CSID'].trim() : row.data['CSID'],
                    channel: row.data.hasOwnProperty('Channel') ? row.data['Channel'].trim() : row.data['Channel'],
                    channel_2: row.data.hasOwnProperty('Channel2') ? row.data['Channel2'].trim() : row.data['Channel2'],
                    customer_chain_static: row.data.hasOwnProperty('Customer Chain Static') ? row.data['Customer Chain Static'].trim() : row.data['Customer Chain Static'],
                    customer_type: row.data.hasOwnProperty('Customer Type') ? row.data['Customer Type'].trim() : row.data['Customer Type'],
                    customer_details: row.data.hasOwnProperty('Customer details') ? row.data['Customer details'].trim() : row.data['Customer details'],
                    days_cover: row.data.hasOwnProperty('Days cover') ? row.data['Days cover'].trim() : row.data['Days cover'],
                    discount: row.data.hasOwnProperty('Discount') ? row.data['Discount'].trim() : row.data['Discount'],
                    distibutor_sales_representative: row.data.hasOwnProperty('Distributor Sales Representative') ? row.data['Distributor Sales Representative'].trim() : row.data['Distributor Sales Representative'],
                    distributor_name: row.data.hasOwnProperty('Distributor/ Account*') ? row.data['Distributor/ Account*'].trim() : row.data['Distributor/ Account*'],
                    owner_mngr_buyer_name: row.data.hasOwnProperty('Owner/Manager/Buyer\'s name') ? row.data['Owner/Manager/Buyer\'s name'].trim() : row.data['Owner/Manager/Buyer\'s name'],
                    frequency: row.data.hasOwnProperty('Frequency') ? row.data['Frequency'].trim() : row.data['Frequency'],
                    region: row.data.hasOwnProperty('Region') ? row.data['Region'].trim() : row.data['Region'],
                    region_str: row.data.hasOwnProperty('Region Str') ? row.data['Region Str'].trim() : row.data['Region Str'],
                    residential_address: row.data.hasOwnProperty('Residential Address') ? row.data['Residential Address'].trim() : row.data['Residential Address'],
                    business_address: row.data.hasOwnProperty('Ship to/Business Address') ? row.data['Ship to/Business Address'].trim() : row.data['Ship to/Business Address'],
                    store_map: row.data.hasOwnProperty('Store map') ? row.data['Store map'].trim() : row.data['Store map'],
                    terms: row.data.hasOwnProperty('Terms') ? row.data['Terms'].trim() : row.data['Terms'],

                  };

                  listData.push(accountData);
                  parser.resume()
                }
              } else {
                has_error++;
                return_message.message = 'Header Count are not match';
                parser.abort();
              }
            },
            complete: function (results) {
              if (has_error === 0) {
                return_message.success = true;
                return_message.data = listData;
              } else {
                return_message.success = false;
              }
              resolve(return_message)
            },
            error: function (error) {
              reject(error);
            },
          });
        });
  };

  function chunkUploadData(results){
    let chunkedData = [];
    let dataArray = [];
    for(let i = 0; i < results.length; i++){
      if(dataArray.length === 200){
        chunkedData.push(dataArray);
        dataArray = [];
      }

      dataArray.push(results[i]);
   
      if ( i === results.length - 1 )
      {
        chunkedData.push(dataArray);
        dataArray = [];
      }
    }
    return chunkedData;
  };

  async function insertProducts(index, chunkedData, totalData){
    let percent = (index / totalData) * 100;
    setCsvUploadPercent((prevProgress) => (prevProgress >= 100 ? percent : percent));
    if(index < chunkedData.length){
      var data = JSON.stringify(chunkedData[index]);
      await axios({
        method: 'POST',
        url: '/ApiAccounts/uploadAccountsMasterFile.json',
        data: objectToFormData({index:index, data:data , is_from_csv:true}),
        dataType: 'json' // Add this line
      }).then((res) =>{
          let res_status = res.data;

          if (Array.isArray(res_status) && res_status.length !== 0) {
            setUploadStatusArray(uploadStatusArray => [...uploadStatusArray, ...res_status]);
          }

          insertProducts(index+1, chunkedData, totalData);
        
      }).catch(error=>{
        setOpenMessageDialog(true);
        console.log('qweqwe');
        setMessage(` ${error.toJSON().message}. Network error.`);

      });
    }else{
      setDoneUploadingProduct(prev => !prev);
      setOpen(false)
    }
  }

  useEffect(()=>{
    if(doneUploadingProduct === true && uploadStatusArray.length <=0){
        setMessage("Account uploaded successfully.")
        setOpenMessageDialog(true);
        setOnEditOrDelete(prev => !prev)
        closeUploadStatusDialog();
    }
  },[doneUploadingProduct])

  function closeUploadStatusDialog() {
    setShowCsvStatusDialog(false)
    setOpen(false);
    setCSVData(null)
  }

  return (
    <div>
      <Button variant="contained" size="small" onClick={handleOpen}>Add Customers for distributor via CSV</Button>
      
      <Dialog fullWidth={true} maxWidth={'md'} open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>Upload Customer</DialogTitle>
        <DialogContent>
        <Box p={1} display="flex" justifyContent="left"  flexDirection="column"><i>
          1. All fields with asterisk are required <br/></i> <br/>
          
          <input accept=".csv" type="file" ref={fileInputRef} onChange={onInputChange}/>
        </Box>
        </DialogContent>
        <DialogActions>
        <CSVTemplateDownloadButton csvContent={generateCSVContent()} filename="Accounts Master File.csv" />
        <Button variant="contained" color='success' component="label" onClick={() => {processUploadProductsCsv(csvData)}}><FileUpload /> Upload
        </Button>
        <Button color="primary" onClick={handleCancel} autoFocus>Close</Button>
        </DialogActions>
      </Dialog>
      <CsvStatusDialog 
        modalTitle={modalTitle} 
        showCsvStatusDialog={showCsvStatusDialog}
        csvUploadPercent={csvUploadPercent}
        uploadStatusArray={uploadStatusArray}
        handleClose ={() => {
          setCSVData(null)
          setShowCsvStatusDialog(false)
          setUploadStatusArray([])
          }}
      />
      <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={handleClose}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default AddAccountsForDistributors;