import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, StyledEngineProvider, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ChangeDistributorAccount({accountsData, setOnEditOrDelete}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    account_code: '',
    customer_chain_array: [],
    customer_chain: '',
    old_customer_chain: '',
    distributor_company_id: '',
    id : ''
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [customerChains, setcustomerChains] = useState([]);
  const [loadingCustomerChains, setLoadingustomerChains] = useState(false);
  const [openCustomerChains, setOpenustomerChains] = useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    
    const { customer_no, id, distributor_account, distributor_company_id} = accountsData;

    setEditData((prevData) => ({ 
      ...prevData, 
      account_code: customer_no || prevData.customer_no, 
      id: id || prevData.id,
      customer_chain_array: {'label' : distributor_account, 'value': distributor_account},
      customer_chain: distributor_account,
      old_customer_chain: distributor_account,
      distributor_company_id: distributor_company_id,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
      account_code: '',
      customer_chain_array: [],
      customer_chain: '',
      old_customer_chain: '',
      distributor_company_id: '',
      id : ''
    });
  };

  const validateRequiredFields = () => {
    const requiredFields = ['customer_chain'];

    for (const field of requiredFields) {
      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisks (*) are required');
        return false;
      }

    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return

    if (!isValidSpecialCharacter(editData.account_name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Account Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.description) && editData.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiAccounts/changeDistributorAccount.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)

        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      console.log(error);
    }
  }

  const getCustomerChains = async (ignore) => {
    setLoadingustomerChains(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerChains/getCustomerChainsForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerChains(response.data);
        }
    setLoadingustomerChains(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerChains) {
      return undefined
    }

    getCustomerChains(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerChains]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCustomerCHainChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevData) => ({ ...prevData, customer_chain_array: selectValue, customer_chain: selectValue.customer_chain}))
    }
  }

  return (
    <div>
      <Button 
        variant="contained" 
        size="small" 
        onClick={(e) => {handleOpen(e)}}
        sx={{
          background: '#9e69af', 
          color: '#fff',
          '&:hover': {
            background: 'rgba(133, 80, 150, 1)',  // Slightly darker shade for hover
          }
        }}
        >
      Change Distributor/Account
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Change Distributor/Account</DialogTitle>
        <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Customer" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    open={openCustomerChains}
                    onOpen={() => {setOpenustomerChains(true);}}
                    onClose={() => {setOpenustomerChains(false);}}
                    loading={loadingCustomerChains}
                    onChange={handleCustomerCHainChange}
                    options={customerChains}
                    value={editData.customer_chain_array}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Distributor/ Chain acct*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingCustomerChains ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  />
                </FormControl>
              </Grid>
            </Grid>
        </CustomTabPanel>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default ChangeDistributorAccount