import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, NativeSelect, StyledEngineProvider, TextField } from '@mui/material';
import React, { useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';

function AddRate({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [haulingrate, setHaulingrate] = useState({
    name: '',
    description: '',
    rate: '',
    sp_branch: '',
    short_text: '',
    town_city: '',
    province: '',
    minimum_assets: '',
    centigen_branch: '',
    active: 0,
  });

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!haulingrate.name) {
        setOpenMessageDialog(true);
        setMessage('Hauling Name are required')
        return
    }

    if (!isValidSpecialCharacter(haulingrate.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Name.')
        return
    }

    if (!isValidSpecialCharacter(haulingrate.description) && haulingrate.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/HaulingRates/addRate.json',
              data: objectToFormData(haulingrate),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setHaulingrate({
      name: '',
      description: '',
      rate: '',
      sp_branch: '',
      short_text: '',
      town_city: '',
      province: '',
      minimum_assets: '',
      centigen_branch: '',
      active: '',
    });
  };

  const handleSelectActiveChange = (event, newValue) => {
    setHaulingrate((prevAccount) => ({ ...prevAccount, active: event.target.value }))
  };

  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Create Hauling rate</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Rate</DialogTitle>
      <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  name="account_name"
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHaulingrate((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={haulingrate.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="Rate"
                  label="Rate*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                              setHaulingrate((prevAccount) => ({ ...prevAccount, rate: input }));
                          }
                      }
                  }}
                  value={haulingrate.rate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHaulingrate((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={haulingrate.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="sp_branch"
                  label="SP Branch"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHaulingrate((prevAccount) => ({ ...prevAccount, sp_branch: e.target.value }))}
                  value={haulingrate.sp_branch}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="short_text"
                  label="Short ext"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHaulingrate((prevAccount) => ({ ...prevAccount, short_text: e.target.value }))}
                  value={haulingrate.short_text}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="town_city"
                  label="Town city"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHaulingrate((prevAccount) => ({ ...prevAccount, town_city: e.target.value }))}
                  value={haulingrate.town_city}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="province"
                  label="Province"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setHaulingrate((prevAccount) => ({ ...prevAccount, province: e.target.value }))}
                  value={haulingrate.province}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="minimum_assets"
                  label="Minimum assets"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                              setHaulingrate((prevAccount) => ({ ...prevAccount, minimum_assets: input }));
                          }
                      }
                  }}
                  value={haulingrate.minimum_assets}
              />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth sx={{marginTop: 2}}>
                  <InputLabel variant="standard">
                  Active
                  </InputLabel>
                  <NativeSelect
                      defaultValue={haulingrate.active}
                      onChange={handleSelectActiveChange}
                  >
                    <option value={0}>Yes</option>
                    <option value={1}>No</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddRate