import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import useAuth from '../../hooks/UseAuth';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function AddServiceRequest({assetCode, setOnEdit}) {
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [stores, setStores] = useState([]);
  const [openStore, setOpenStore] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [serviceRequest, setServiceRequest] = useState({
    assetSelected: [],
    asset_code: '',
    asset_name:'',
    warehouseSelected: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    stores: [],
    account_unique_code: '',
    complaints: '',
    servicesSelected: [],
    services: '',
    distributor_company_id: '',
  });

  const [openService, setOpenService] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      return setServiceRequest(
        (prevAccount) => ({ ...prevAccount, assetSelected: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name})
      )
    } 

    setServiceRequest((prevAccount) => ({ ...prevAccount, assetSelected: [], asset_code: '', asset_name: ''}))
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setServiceRequest(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setServiceRequest((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }

  const hanldeServicesChange = (e, selectValue) => {
    if (selectValue) {
      let services = selectValue.map(item => item.service_code);
      return setServiceRequest((prevAccount) => ({ ...prevAccount, servicesSelected: selectValue,  services: services}))
    } 
    
    setServiceRequest((prevAccount) => ({ ...prevAccount, servicesSelected: [], services: []}))
  }

  const hanldeStoreChange = (e, selectValue) => {
    if (selectValue) {
      return setServiceRequest(
        (prevAccount) => ({ 
          ...prevAccount, 
          stores: selectValue, 
          account_unique_code: selectValue.account_unique_code, 
          distributor_company_id: selectValue.distributor_company_id,
          account_name: selectValue.account_name,
        })
      )
    } 

    setServiceRequest((prevAccount) => ({ ...prevAccount, stores: [], account_code: '', branch_code: ''}))
  }



  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  
  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/StoreAssets/getAssetsInStore.json',
            data: {asset_code: assetCode}
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getStore = async (ignore) => {
    setStoreLoading(true)
    try {
        const options = {
            method: 'POST',
            url: '/StoreAssets/getStoreforServiceRequest.json',
            data: {asset_code: serviceRequest.asset_code}
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);
          setStoreLoading(false)
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getServices= async(ignore) => {
    try {
      setServiceLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetServices/getAllServicesForAutoCompleteAllSerivceCall.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setServices(response.data);
        }
        setServiceLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openService) return undefined
    getServices(ignore)

    return () => {
      ignore = true
    };
  }, [openService]);

  useEffect(() => {
    let ignore = false;
    if (!openAssets) {
      setAssets([]);
    }

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  useEffect(() => {
    let ignore = false;
    if (!openStore) {
      setStores([]);
    }

    if (!openStore) return undefined
    getStore(ignore)

    return () => {
      ignore = true
    };
  }, [openStore]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (serviceRequest.warehouseSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Warehouse is required.');
      return false;
    }
    if (serviceRequest.assetSelected.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Asset is required.');
      return false;
    }
    if (serviceRequest.stores.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Stores is required.');
      return false;
    }

    if (!isValidSpecialCharacter(serviceRequest.complaints) && serviceRequest.complaints) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in complaints.')
      return
    }

    try {

      const options = {
          method: 'POST',
            url: '/ServiceRequests/addRequest.json',
            data: objectToFormData(serviceRequest),
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEdit((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setServiceRequest({
      assetSelected: [],
      asset_code: '',
      asset_name:'',
      warehouseSelected: [],
      stores: [],
      account_unique_code: '',
      complaints: '',
      servicesSelected: [],
      services: '',
      distributor_company_id: '',
    });
  };

  return (
    <>
      <Button
      disabled={Number(auth.role) === 4 ? true : false}
      variant="contained" 
      size="small" 
      onClick={handleOpen}
      >Create Request</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New Service request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={warehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Centigen Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openAssets}
                onOpen={() => {setOpenAssets(true);}}
                onClose={() => {setOpenAssets(false);}}
                loading={loading}
                onChange={hanldeAssetsChange}
                options={assets}
                value={assets.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select asset*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openStore}
                onOpen={() => {setOpenStore(true);}}
                onClose={() => {setOpenStore(false);}}
                loading={storeLoading}
                onChange={hanldeStoreChange}
                options={stores}
                value={stores.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Store*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {storeLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography>Complaints:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Input complaints" 
                onChange={(e) => setServiceRequest((prevAccount) => ({ ...prevAccount, complaints: e.target.value }))}
              />
          </Grid>

          <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      disableCloseOnSelect
                      multiple
                      open={openService}
                      onOpen={() => {setOpenService(true);}}
                      onClose={() => {setOpenService(false);}}
                      onChange={hanldeServicesChange}
                      options={services}
                      value={services.label}
                      loading={serviceLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Service*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {serviceLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddServiceRequest