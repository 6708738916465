import { Avatar, Box, Chip, CircularProgress, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddWarehouseAudit from '../WarehouseAudit/AddWarehouseAudit';
// import EditWarehouseAudit from './EditWarehouseAudit';
// import DeleteWarehouseAudit from './DeleteWarehouseAudit';
import CloseWarehouseAudit from '../WarehouseAudit/CloseWarehouseAudit';

const WarehouseAuditRequest = ({assetCode}) => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [auditAssets, setAuditAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'asset_code', headerName: 'Asset Code', flex: 1, minWidth: 150, wrap: true },
    { field: 'asset_name', headerName: 'Asset Name',flex: 1, minWidth: 150, wrap: true  },
    { field: 'warehouse_code', headerName: 'Warehouse code',flex: 1, minWidth: 150,},
    { field: 'warehouse_name', headerName: 'Warehouse name',flex: 1, minWidth: 150,},
    { field: 'remarks', headerName: 'Remarks',flex: 1, minWidth: 150,},
    { 
      field: 'status', 
      headerName: 'Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='Processing' color='primary' />;
          } else if (statusName === 1) {
            return <Chip label='Closed' color='success' />;
          } else {
            return '';
          }
          
      }
  },
    { field: 'created', headerName: 'Created',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'modified', headerName: 'Modified',flex: 1, minWidth: 200,  
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 250,
      wrap: true,
      renderCell: (params) => (
        <>
          <CloseWarehouseAudit auditAssets={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          {/* <EditWarehouseAudit auditAssets={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteWarehouseAudit auditAssets={params.row} setOnEditOrDelete={setOnEditOrDelete}/> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      try {
        setLoading(true);
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getWarehouseAuditPerAsset.json',
          'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        const data = response.data;

        setAuditAssets(data)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      } finally {
        setLoading(false);
      }
    }

    getAssetTypes();
  }, [onEditOrDelete]);
  return (
    <>
    <Box sx={{ marginBottom: '20px' }}>
      <AddWarehouseAudit assetCode={assetCode} setOnEditOrDelete={setOnEditOrDelete}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight
        rows={auditAssets}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        loading={loading}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default WarehouseAuditRequest