import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData, validateAlphaNumeric } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';

function AddCustomerChain({setOnEditOrDelete}) {
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [customerChain, setCusomterChain] = useState({
    chain_code: '',
    chain_name: '',
    customer_group: '',
    customer_type_array: [],
    customer_type: '',
    active: '',
  });

  const [customerTypes, setcustomerTypes] = useState([]);
  const [loadingCustomerTypes, setLoadingustomerTypes] = useState(false);
  const [openCustomerTypes, setOpenustomerTypes] = useState(false);


  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const allowedActiveInput = ['Yes', 'No'];
    if (!customerChain.chain_code) {
        setOpenMessageDialog(true);
        setMessage('Customer chain code is required')
        return
    }
    if (!customerChain.chain_name) {
        setOpenMessageDialog(true);
        setMessage('Customer chain name is required')
        return
    }

    if (!customerChain.customer_type) {
        setOpenMessageDialog(true);
        setMessage('Customer type is required')
        return
    }
    if (!customerChain.active) {
        setOpenMessageDialog(true);
        setMessage('Active is required')
        return
    }

    if (!isValidSpecialCharacter(customerChain.chain_code)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Customer chain code.')
        return
    }

    if (!isValidSpecialCharacter(customerChain.chain_name) && customerChain.chain_name !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Customer chain name.')
        return
    }
    
    if (!isValidSpecialCharacter(customerChain.customer_group) && customerChain.customer_group !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Customer group.')
        return
    }

    if (!allowedActiveInput.includes(customerChain.active) && customerChain.active !== '') {
        setOpenMessageDialog(true);
        setMessage('Invalid value for active. It must be either Yes or No.')
        return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/CustomerChains/addCustomerChain.json',
              data: objectToFormData(customerChain),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's an error from the server. Please contact the provider.`);
    }
  }

  const resetForm = () => {
    setCusomterChain({
      chain_code: '',
      chain_name: '',
      customer_group: '',
      customer_type_array: [],
      customer_type: '',
      active: '',
    });
  };

  const handleCustomerTypeChange = (e, selectValue) => {
    if (selectValue) {
      setCusomterChain((prevData) => ({ ...prevData, customer_type_array: selectValue, customer_type: selectValue.customer_type}))
    }
  }

  const getCustomerTypes = async (ignore) => {
    setLoadingustomerTypes(true)
    try {
        const options = {
            method: 'POST',
            url: '/CustomerTypes/getCustomerTypesForAutocomplete.json',
        }

        const response = await axios(options);

        if(!ignore) {
          setcustomerTypes(response.data);
        }
    setLoadingustomerTypes(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(()=>{
    let ignore = false;
  
    if (!openCustomerTypes) {
      return undefined
    }

    getCustomerTypes(ignore);

    return () => {
      ignore = true;
    }
  }, [openCustomerTypes]);


  return (
    <div>
    <Button variant="contained" size="small" onClick={handleOpen}>Add</Button>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add New Chain</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Customer chain code*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setCusomterChain((prevAccount) => ({ ...prevAccount, chain_code: e.target.value }))}
                  value={customerChain.chain_code}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Customer chain name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setCusomterChain((prevAccount) => ({ ...prevAccount, chain_name: e.target.value }))}
                  value={customerChain.chain_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Customer chain group"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setCusomterChain((prevAccount) => ({ ...prevAccount, customer_group: e.target.value }))}
                  value={customerChain.customer_group}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="name"
                  label="Active*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setCusomterChain((prevAccount) => ({ ...prevAccount, active: e.target.value }))}
                  value={customerChain.active}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  open={openCustomerTypes}
                  onOpen={() => {setOpenustomerTypes(true);}}
                  onClose={() => {setOpenustomerTypes(false);}}
                  loading={loadingCustomerTypes}
                  onChange={handleCustomerTypeChange}
                  options={customerTypes}
                  value={customerTypes.label}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer type*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingCustomerTypes ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AddCustomerChain