import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, NativeSelect, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';

const EditPrincipal = ({usersData, setOnEditOrDelete}) => {
  const theme = useTheme()
  const axios = AxiosCustomConfig()
  const [open, setOpen] = useState(false);
  const [ediAccount, setEdiAccount] = useState({
    username: '',
    email_address: '',
    password: 'defaultvalue',
    first_name: '',
    middle_name: '',
    last_name: '',
    mobile_number: '',
    role_id: '',
    maximum_users: '',
  });
  const [showConfirmPassword, setshowConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [showProgress,setShowProgress] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('defaultvalue');

  //handle functions
  const resetForm = () => {
    setEdiAccount({
      id: '',
      company_id: '',
      username: '',
      email_address: '',
      password: 'defaultvalue',
      first_name: '',
      middle_name: '',
      last_name: '',
      mobile_number: '',
      role_id: '',
      maximum_users: '',
      super_user: 0,
    });
    setConfirmPassword('defaultvalue');
  };

  const objectToFormData = (object) => {
    const formData = new FormData();
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        formData.append(key, object[key]);
      }
    }

    return formData;
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword((prev) => !prev);
  };

  const handleButtonOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
    const { id, company_id, username, email_address, first_name, middle_name, last_name, mobile_number, role_id, maximum_users } = usersData;
    let superUser = role_id === 0 ? 1 : 0;
    
    setEdiAccount((prevData) => ({ 
      ...prevData, 
      id: id || prevData.id, 
      company_id: company_id || prevData.company_id, 
      username: username || prevData.username, 
      email_address: email_address || prevData.email_address, 
      first_name: first_name || prevData.first_name, 
      middle_name: middle_name || prevData.middle_name, 
      last_name: last_name || prevData.last_name, 
      mobile_number: mobile_number || prevData.mobile_number, 
      role_id: role_id || prevData.role_id, 
      maximum_users: maximum_users || prevData.maximum_users, 
      super_user: superUser, 
    }))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectRoleChange = (event) => {
    setEdiAccount((prevAccount) => ({ ...prevAccount, role_id: event.target.value }))
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ediAccount.username || !ediAccount.first_name || !ediAccount.middle_name || !ediAccount.last_name || !ediAccount.mobile_number || !ediAccount.email_address || !ediAccount.password) {
      setOpenMessageDialog(true);
      setMessage('All Fields are required')
      return
    }

    if(ediAccount.password !== confirmPassword) {
      setOpenMessageDialog(true);
      setMessage('Password did not match.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/ApiUsers/updatePrincipal.json',
              data: objectToFormData(ediAccount),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.result.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.result.message)
          resetForm();
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage('Failed to Update the Principal.')
        }
        
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Button
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleButtonOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Create Principal Account</DialogTitle>
        <DialogContent>
        <Box sx={{marginBottom: 2 }}>
          <Chip label="Credentials" size="small" color="secondary" />
        </Box>
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <TextField
                required
                id="username"
                name="username"
                label="Username"
                fullWidth
                variant="standard"
                value={ediAccount.username}
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, username: e.target.value }))}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                fullWidth
                variant="standard"
                value={ediAccount.password}
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, password: e.target.value }))}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                fullWidth
                variant="standard"
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleConfirmPasswordVisibility}
                        >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                value={confirmPassword}
            />
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
            <InputLabel variant="standard">
            Role
            </InputLabel>
            <NativeSelect
                defaultValue={ediAccount.role_id}
                inputProps={{
                    name: 'role',
                    id: 'role',
                }}
                onChange={handleSelectRoleChange}
            >
            <option value={2}>Principal</option>
            </NativeSelect>
            </FormControl>
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Access Control" size="small" color="secondary" />
            </Box>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="superuser"
                    name="superuser"
                    checked={ediAccount.super_user}
                    onChange={(e) =>
                      setEdiAccount((prevAccount) => ({
                        ...prevAccount,
                        super_user: e.target.checked ? 1 : 0,
                      }))
                    }
                  />
                }
                label="Is Super User"
              />
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Personal Information" size="small" color="secondary" />
            </Box>
            <Grid item xs={12}>
            <TextField
                required
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                variant="standard"
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, first_name: e.target.value }))}
                value={ediAccount.first_name}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                id="middleName"
                name="middleName"
                label="Middle name"
                fullWidth
                variant="standard"
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, middle_name: e.target.value }))}
                value={ediAccount.middle_name}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                variant="standard"
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, last_name: e.target.value }))}
                value={ediAccount.last_name}
            />
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
              <Chip label="Contacts" size="small" color="secondary"/>
            </Box>
            <Grid item xs={12}>
            <TextField
                required
                id="mobile"
                name="mobile"
                label="Mobile Number"
                fullWidth
                variant="standard"
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, mobile_number: e.target.value }))}
                value={ediAccount.mobile_number}
            />
            </Grid>
            <Grid item xs={12}>
            <TextField
                required
                id="emailAddress"
                name="emailAddress"
                label="Email Address"
                fullWidth
                variant="standard"
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, email_address: e.target.value }))}
                value={ediAccount.email_address}
            />
            </Grid>
            <Box sx={{ marginTop: 5, marginBottom: 2 }}>
            <Chip label="Users Limit" size="small" color="secondary" />
            </Box>
            <Grid item xs={12}>
            <TextField
                required
                id="maxUsers"
                name="maxUsers"
                label="Maximum Users"
                fullWidth
                variant="standard"
                onChange={(e) => setEdiAccount((prevAccount) => ({ ...prevAccount, maximum_users: e.target.value }))}
                value={ediAccount.maximum_users}
            />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button onClick={handleClose}>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>
      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              setOnEditOrDelete((prev) => !prev)
              }}
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditPrincipal