import { useEffect, useState, useCallback } from 'react';
import { AxiosCustomConfig } from '../config/AxiosCustomConfig';

const useFetchColumns = (functionName, onSaveColumns, setOpenMessageDialog, setMessage) => {
  const [columns, setColumns] = useState([]);
  const axios = useCallback(AxiosCustomConfig(), []);

  useEffect(() => {
    const getColumns = async () => {
      try {
        const options = {
          method: 'POST',
          url: '/CsvColumnSettings/getColumns.json',
          data: { function_name: functionName }
        };
        const response = await axios(options);
        setColumns(response.data.data);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    };

    getColumns();
  }, [functionName, onSaveColumns, axios, setOpenMessageDialog, setMessage]);

  return columns;
};

export default useFetchColumns;
