import { Button, useTheme } from '@mui/material'
import React from 'react'
import useAuth from '../../hooks/UseAuth';

function ViewAssetBtn({assetsData}) {
  const theme = useTheme();
  const { auth } = useAuth();
  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        disabled={Number(auth.role === 6) ? true : false}
        onClick={() => { window.location.href = `/asset/${assetsData.id}`; }}
      >
      View
      </Button>
    </div>
  )
}

export default ViewAssetBtn