
import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Grid, Paper, StyledEngineProvider, Typography, Box, Table, TableHead, TableBody, TableRow, TableCell, Chip, Breadcrumbs, Accordion, AccordionSummary, AccordionDetails, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '@mui/material';
import MessageDialog from '../Utils/MessageDialog';
import styled from '@emotion/styled';
import useAuth from '../../hooks/UseAuth';
import { format } from 'date-fns';
import { TransferAssetRequestProvider } from '../../context/TransferAssetRequestProvider';
import TransferAssetLists from './TransferAssetLists';
import EditTransferAssetRequest from './EditTransferAssetRequest';
import ApproveTransferRequest from './ApproveTransferRequest';
import DeployTransferAssetRequest from './DeployTransferAssetRequest';
import ConfirmDeployAssets from './ConfirmDeployAssets';
import MoreOptionBtn from './MoreOptionBtn';
import OtherCharges from './OtherCharges';
import { ArrowDownward, Download } from '@mui/icons-material';
import { Document, Page } from 'react-pdf';


const StyledTable = styled(Table)(
  ({ theme }) => `
  // td,th
  // {
  //     // border: none;
  // }
  // `,
);
const ViewOtherCharges = () => {
  const { requestCode, id } = useParams();
  const axios = AxiosCustomConfig();
  const {auth} = useAuth();
  const [doneUpdateRequest, setDoneUpdateRequest] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [fileType, setFileType] = useState('');
  const [content, setContent] = useState('');

  const [transferRequests, setTransferRequests] = useState({});
  // const [assetCode, setAssetCode] = useState([]);
  const getAssets = async () => {
    try {
      setLoading(true);
      const options = {
          'method': 'POST',
          'url' : '/OtherCharges/getOtherChargesDetails.json',
          'data': {'id': id}
      }
      const response = await axios(options);
      const data = response.data;
      setTransferRequests(data);
      setLoading(false);
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAssets();
  }, [doneUpdateRequest]);

  function formatKey(key) {
    // Replace underscores with spaces and capitalize each word
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  const isPdf = transferRequests?.charge_image?.toLowerCase().endsWith('.pdf');

  useEffect(() => {
    if (transferRequests.image_url) {
      const fileExtension = transferRequests.image_url.split(';')[0].split('/')[1];
      setFileType(fileExtension === 'pdf' ? 'pdf' : 'image');
      setContent(transferRequests.image_url);
    } else {
      setContent('');
      setFileType('');
    }
  }, [transferRequests.image_url]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TransferAssetRequestProvider>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            to="/transferAssets"
          >
            Transfer Assets
          </Link>
          <Link
            color="inherit"
            to={`/transferAssets/${requestCode}`}
          >
            Details
          </Link>
          <Link
            color="inherit"
            to={`/transferAssets/${requestCode}/OtherCharges`}
          >
            Transfer other charges
          </Link>
          <Typography color="text.primary">{transferRequests.charge_name}</Typography>
        </Breadcrumbs>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container direction='column' spacing={1}>
              <Grid item>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ArrowDownward />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                    <Typography variant="h6">{transferRequests.charge_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      Attachments
                      <Box display="flex" alignItems="center" width="100%">
                        <Typography 
                          variant="body1" 
                          style={{ cursor: 'pointer', textDecoration: 'underline' }} 
                          onClick={handleClickOpen}
                        >
                          {transferRequests.charge_image}
                        </Typography>
                        <IconButton 
                          aria-label="download" 
                          onClick={() => {
                            window.open(transferRequests.image_url_dl, '_blank');
                          }}>
                          <Download />
                        </IconButton>
                      </Box>
                    </AccordionDetails>
                </Accordion>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={1} style={{ height: '100%' }}>
            <Typography p={2}>Info</Typography>
              <StyledTable sx={{ width: '100%' }} size="small">
                  <TableHead>
                  </TableHead>
                  <TableBody>
                  {Object.entries(transferRequests)
                    .filter(([key, _]) =>  key !== 'charge_name' && key !== 'charge_image' && key !== 'image_url' && key !== 'image_url_dl')
                    .map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell><strong>{formatKey(key)}:</strong></TableCell>
                      <TableCell>
                        {key === 'date_added' ? (
                            value !== null ? format(value, 'yyyy-MM-dd HH:mm:ss') : ''
                        ) : (
                            value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
              </StyledTable>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent>
        {isPdf ? (
          <iframe
              src={transferRequests.image_url}
              title="PDF"
              width="100%"
              height="500px"
            />
        ) : (
          <img
            src={transferRequests.image_url}
            alt="receipt"
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            }}
          message={message}
        />
    </StyledEngineProvider>
    </TransferAssetRequestProvider>
  )
}

export default ViewOtherCharges