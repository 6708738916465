import { Box, Chip, Grid, StyledEngineProvider } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddTransferAsset from '../TransferAssetsWarehouse/AddTransferAsset';

const TransferAssetRequest = ({assetCode}) => {
  const axios = AxiosCustomConfig();
  const [onEdit, setOnEdit] = useState(false);
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'id', headerName: 'Id', width: 50, minWidth: 20, wrap: true,
    },
    { field: 'request_code', headerName: 'Transfer request #',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/transferAssets/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
        field: 'status', 
        headerName: 'Service Status',
        flex: 1, 
        minWidth: 100, 
        wrap: true,
        renderCell: (params) => {
            const status = params.value;
            if (status === 0) {
              return <Chip label='For approval' sx={{ backgroundColor: '#35A7CA' }} />;
            } else if (status === 1) {
              return <Chip label='Delivery in progress' sx={{ backgroundColor: '#D7D6D4'}} />;
            } else if (status === 2) {
              return <Chip label='Completed' sx={{ backgroundColor: '#3AD658' }} />;
            } else if (status === 3) {
              return <Chip label='Delivered' color='success' />;
            } else if (status === 4) {
              return <Chip label='Transfer form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
            } else if (status === 5) {
              return <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
            } else if (status === 6) {
              return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
            } else if (status === 7) {
              return <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />;
            } else {
              return '';
            }
            
        }
    },
    { field: 'assets', headerName: 'Assets',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const services = params.value.split(',').map(service => service.trim());
            return (
                <>
                  <Grid container direction='column'>
                    {services.map((service, index) => (
                      <Grid key={index}>
                        {service}
                      </Grid>
                    ))}
                  </Grid>
                </>
            );
          }
          return null
      },
    },
    { field: 'origin_warehouse_name', headerName: 'From warehouse',flex: 1, minWidth: 150,},
    { field: 'destination_warehouse_name', headerName: 'Destination warehouse',flex: 1, minWidth: 150,},
    { field: 'created', headerName: 'Date Uploaded',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
      }, wrap: true 
    },
    { field: 'requestor_name', headerName: 'Requested by',flex: 1, minWidth: 150,},
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/AssetsRegistry/getTransferAssetsPerAsset.json',
        'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        const data = response.data;

        setAssetDisposalData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEdit]);


  return (
    <>
    <Box sx={{ marginBottom: '20px' }}>
      <AddTransferAsset assetCode={assetCode} setOnEdit={setOnEdit}/>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={assetDisposalData}
        columns={columns}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        autoHeight
        loading={loading}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default TransferAssetRequest